import { render, staticRenderFns } from "./ScreeningsUpdateCategoryAddOrEditType.vue?vue&type=template&id=1ae631e2&scoped=true&"
import script from "./ScreeningsUpdateCategoryAddOrEditType.vue?vue&type=script&lang=js&"
export * from "./ScreeningsUpdateCategoryAddOrEditType.vue?vue&type=script&lang=js&"
import style0 from "./ScreeningsUpdateCategoryAddOrEditType.vue?vue&type=style&index=0&id=1ae631e2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae631e2",
  null
  
)

export default component.exports