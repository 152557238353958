<template>
  <div class="InterConnectionSelectModal">
    <div class="TitleWrap">
      <span class="Title">{{ mixWB('SELECT_TYPE') }}</span>
    </div>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('NONE')"
        color="grey"
        outlined
        @button-click="onNoTypeClick" />
      <Select
        name="categories"
        :optionGroups="categoryOptionGroups"
        :selectedID="selectedCategory.id"
        @change="onSelectChange"/>
    </div>

    <div class="ListWrap">
      <CategoryTypeList
        :categoryID="selectedCategory.id"
        :overlayData="{
          showOuterSelect,
          showInnerSelect,
          typeData,
          interConnectionGroupID,
          allInterConnections,
          allScreeningTypes,
        }" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/Buttons/Button.vue'
import Select from '@/components/FormElements/Select.vue'
import CategoryTypeList from '@/components/ScreeningItems/CategoryTypeList.vue'

export default {
  name: 'InterConnectionSelectModal',
  props: {
    showOuterSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showInnerSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    typeData: {
      type: Object,
      required: true,
    },
    interConnectionGroupID: {
      type: String,
      required: false,
      default: '',
    },
    allInterConnections: {
      type: Array,
      required: true,
    },
    allScreeningTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCategory: null,
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'currentCategory',
    ]),
    categoryOptionGroups() {
      let lastCategoryPlace = ''
      return this.categories.reduce((prev, category) => {
        if (this.currentCategory.place === 'outside' && category.place === 'inside') {
          return prev
        }
        if (this.currentCategory.place === 'inside' && category.place === 'outside') {
          return prev
        }

        if (category.place !== lastCategoryPlace) {
          prev.push({
            title: this.mixWB(category.place.toUpperCase()),
            options: [],
          })
          lastCategoryPlace = category.place
        }

        const lastGroup = prev[prev.length - 1]
        lastGroup.options.push({
          id: category.id,
          text: this.mixWB(category.wordbook),
        })

        return prev
      }, [])
    },
  },
  methods: {
    onNoTypeClick() {
      this.$emit('pass-to-parent', {
        name: 'no-type-selected',
        value: { side: this.showOuterSelect ? 'outer' : 'inner' },
      })
    },
    onSelectChange({ value }) {
      this.selectedCategory = this.categories.find((x) => x.id === value)
    },
  },
  components: {
    Button,
    Select,
    CategoryTypeList,
  },
  created() {
    this.selectedCategory = this.currentCategory
  },
}
</script>

<style lang="stylus" scoped>
  .InterConnectionSelectModal
    display block

  .TitleWrap
    margin-bottom 20px
    .Title
      modalTitle()
      margin-bottom 5px

  .ButtonWrap
    margin-bottom 20px
</style>
