<template>
  <div
    ref="mainWrap"
    class="ScreeningsUpdateCategoryAddType">
    <!-- Close -->
    <CloseButton :closePath="getClosePath()" />

    <!-- Header -->
    <span class="CategoryTitle">{{ currentCategoryTitle }}</span>

    <!-- Body -->
    <div class="TypeSteps">
      <!-- Units -->
      <UnitSelect
        :isActive="activeStepNo === 0"
        @units-update="onUnitsUpdate"
        @units-next-click="onUnitNextsClick"/>

      <!-- Images -->
      <ImageSelect
        ref="ImageSelect"
        v-show="activeStepNo >= 1"
        groupName="main-type"
        :isActive="activeStepNo === 1"
        :categoryID="typeData.categoryID || ''"
        @image-list-update="onImageListUpdate" />

      <!-- Types -->
      <TypeSelect
        v-show="activeStepNo >= 1"
        :isActive="activeStepNo === 1"
        :types="currentCategoryTypes"
        :selectedID="typeData.type.typeID"
        :isOtherType="typeData.type.isOtherType"
        :otherType="typeData.type.otherType"
        showOtherOption
        @type-group-click="onTypeGroupClick"
        @type-click="onTypeClick"
        @other-type-click="onOtherTypeClick"
        @other-type-input-updated="onOtherTypeInputUpdated"
        @other-type-selected="onOtherTypeSelected" />

      <!-- Questions -->
      <QuestionsSelect
        v-show="activeStepNo >= 2 && typeQuestions.length"
        :isActive="activeStepNo === 2"
        :title="mixWB('QUESTIONS_ABOUT_THE_TYPE')"
        :questions="typeQuestions"
        :resetQuestionsCounter="resetQuestionsCounter"
        @questions-updated="onQuestionsUpdated"
        @questions-all-answered="onQuestionsAllAnswered" />

      <!-- Work description group select -->
      <WorkDescriptionGroupSelect
        v-show="activeStepNo >= 3 && typeData.type.isOtherType"
        :selectedID="typeData.type.otherTypeWDGID"
        @group-click="onGroupClick" />

      <!-- Sub questions -->
      <SubQuestionsSelect
        v-show="activeStepNo >= 4 && typeSubQuestions.length"
        :isActive="activeStepNo === 4"
        :followUpQuestions="typeSubQuestions"
        :preTitle="mixWB(selectedCategoryType.wordbook)"
        :resetSubQuestionsCounter="resetSubQuestionsCounter"
        :currentCategory="currentCategory"
        @sub-questions-updated="onSubQuestionsUpdated"
        @sub-questions-finished="onSubQuestionsFinished" />

      <!-- Coating  -->
      <CoatingSelect
        v-show="activeStepNo >= 5 && this.showCoatingSelect"
        :resetCoatingCounter="resetCoatingCounter"
        groupName="main-type"
        :materialName="typeData.getTitle()"
        @coating-updated="onCoatingUpdated"
        @coating-finished="onCoatingFinished" />

      <!-- Underline -->
      <UnderlineSelect
        v-show="activeStepNo >= 6 && this.selectedCategoryType.askForUnderlining"
        :resetUnderliningCounter="resetUnderliningCounter"
        groupName="main-type"
        @underlining-updated="onUnderliningUpdated"
        @underlining-finished="onUnderliningFinished" />

      <!-- Auto types -->
      <AutoTypesSelect
        v-if="isNewType"
        v-show="activeStepNo >= 7 && showAutoTypes"
        :categoryID="typeData.categoryID"
        :typeID="typeData.id"
        :categoryType="selectedCategoryType"
        :mainTypeWDGID="typeData.getWDGID()"
        @show-auto-types-status="onShowAutoTypesStatus"
        @auto-type-update="onAutoTypeUpdate"
        @auto-types-finished="onAutoTypesFinished" />

      <!-- Amount -->
      <AmountSelect
        v-show="activeStepNo >= 8 && metaData.doWasteReview"
        :isActive="activeStepNo === 8"
        :buttonText="mixWB('NEXT')"
        :resetAmountCounter="resetAmountCounter"
        :selectedUnitIDs="typeData.unitIDs"
        @type-selected="onAmountTypeSelected"
        @amount-input-updated="onAmountInputUpdated"
        @amount-selected="onAmountSelected" />

      <!-- Resale -->
      <ResaleSelect
        ref="ResaleSelect"
        v-show="activeStepNo >= 9 && showResaleSelect"
        :typeData="typeData"
        :mainTypeSampleIDs="typeData.samples.sampleIDs"
        :resetResaleCounter="resetResaleCounter"
        @resale-update="onResaleUpdate" />

      <!-- Places -->
      <PlacesSelect
        v-show="showSaveButton && this.currentCategory.place !== 'outside'"
        :typeData="typeData"
        @place-update="onPlaceUpdate" />

      <!-- Inter connections -->
      <InterConnectionsSelect
        ref="InterConnectionsSelect"
        v-show="showSaveButton"
        :typeData="typeData"
        :autoTypes="showSaveButton ? autoTypes : []"
        :categoryType="selectedCategoryType"
        @data-update="onInterConnectionDataUpdate"
        @exists-on-its-own-update="onExistsOnItsOwnUpdate" />

      <!-- Description -->
      <Description
        v-show="showSaveButton"
        @description-update="onDescriptionUpdate" />

      <!-- Next/Save button -->
      <div
        v-if="showSaveButton"
        class="ButtonWrap">
        <Button
          :text="mixWB('SAVE')"
          :isLoading="isLoading"
          @button-click="onSaveButtonClick" />
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import AmountSelect from '@/components/ScreeningTypeSections/AmountSelect.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import WorkDescriptionGroupSelect from '@/components/ScreeningTypeSections/WorkDescriptionGroupSelect.vue'
import SubQuestionsSelect from '@/components/ScreeningTypeSections/SubQuestionsSelect.vue'
import CoatingSelect from '@/components/ScreeningTypeSections/CoatingSelect.vue'
import UnderlineSelect from '@/components/ScreeningTypeSections/UnderlineSelect.vue'
import AutoTypesSelect from '@/components/ScreeningTypeSections/AutoTypesSelect.vue'
import ResaleSelect from '@/components/ScreeningTypeSections/ResaleSelect.vue'
import TypeSelect from '@/components/ScreeningTypeSections/TypeSelect.vue'
import ImageSelect from '@/components/ScreeningTypeSections/ImageSelect.vue'
import PlacesSelect from '@/components/ScreeningTypeSections/PlacesSelect.vue'
import InterConnectionsSelect from '@/components/ScreeningTypeSections/InterConnections/InterConnectionsSelect.vue'
import Description from '@/components/ScreeningTypeSections/Description.vue'
import Button from '@/components/Buttons/Button.vue'
import EventBus from '@/EventBus'
import { findTags, findSamples } from '@/views/helpers'
import { scrollTo } from '@/globals/javascript/_util/util'
import UnitSelect from '@/components/ScreeningTypeSections/UnitSelect.vue'
import Type from '@/globals/javascript/models/Type'
import Sample from '@/globals/javascript/models/Sample'
import Unit from '@/globals/javascript/models/units/Unit'

export default {
  name: 'ScreeningsUpdateCategoryAddType',
  data() {
    return {
      activeStepNo: 0,
      lastActiveNo: 0,
      isLoading: false,
      typeData: {},
      autoTypes: [],
      showAutoTypes: false,
      areAutoTypesLoaded: false,
      isScrolledAllowed: false,
      roadMap: [
        'units', // Step 0
        'typeAndImages', // Step 1
        'questions', // Step 2
        'work-description-group', // Step 3
        'subQuestions', // Step 4
        'coating', // Step 5
        'underlining', // Step 6
        'auto-types', // Step 7
        'amount', // Step 8
        'resale', // Step 9
        'finished',
      ],
      hadAutoAssessmentsOnLoad: false,
      // Inter connection data
      addedInterConnections: [],
      removedInterConnectionIDs: [],
      typesExistsOnTheirOwnAnswers: {},
      existingInterConnectionGroupsToCopy: [],
      // Resets
      resetCoatingCounter: 0,
      resetUnderliningCounter: 0,
      resetAmountCounter: 0,
      resetResaleCounter: 0,
      resetQuestionsCounter: 0,
      resetSubQuestionsCounter: 0,
    }
  },
  computed: {
    ...mapGetters([
      'metaData',
      'categories',
      'categoryTypes',
      'screeningTypes',
      'categoryTypeSubQuestions',
      'categoryOtherTypeQuestions',
      'currentRoute',
      'currentCategory',
      'currentCategoryTitle',
      'screeningSamples',
      'nextAssessNumber',
    ]),
    isNewType() {
      return this.currentRoute.name === 'ScreeningsUpdateCategoryAddType'
    },
    currentCategory() {
      return this.categories.find((x) => x.id === this.typeData.categoryID)
    },
    currentCategoryTypes() {
      if (!this.currentCategory) {
        return []
      }
      return this.categoryTypes.filter((x) => this.currentCategory.types.indexOf(x.id) > -1)
    },
    selectedCategoryType() {
      return this.currentCategoryTypes.find((x) => x.id === this.typeData.type.typeID) || {}
    },
    typeQuestions() {
      // Check if other type is selected
      if (this.typeData.type.isOtherType) {
        return this.categoryOtherTypeQuestions
      }
      if (!this.selectedCategoryType.id) {
        return []
      }
      if (!this.selectedCategoryType.questions) {
        return []
      }
      const questionsCopy = [...this.selectedCategoryType.questions]
      return questionsCopy.sort((a, b) => (a.position > b.position ? 1 : -1))
    },
    typeSubQuestions() {
      if (!this.selectedCategoryType.id) {
        return []
      }

      if (
        !this.selectedCategoryType.subQuestions
         || !this.selectedCategoryType.subQuestions.length
      ) {
        return []
      }

      return this.categoryTypeSubQuestions.filter(
        (x) => this.selectedCategoryType.subQuestions.includes(x.id),
      )
    },
    showCoatingSelect() {
      if (['C-16'].includes(this.currentCategory.id)) {
        return false
      }
      if (this.currentCategory.askForCoating) {
        return true
      }
      if (this.selectedCategoryType.askForCoating) {
        return true
      }
      if (this.typeData.type.isOtherType) {
        return true
      }

      return false
    },
    showResaleSelect() {
      if (['C-16'].includes(this.currentCategory.id)) {
        return false
      }
      return !this.selectedCategoryType.hideResale
    },
    activeStep() {
      return this.roadMap[this.activeStepNo]
    },
    isButtonActive() {
      if (this.activeStep === 'units') {
        if (!this.typeData.unitIDs.length) {
          return false
        }
        return true
      }
      if (this.activeStep === 'typeAndImages') {
        if (!this.typeData.type.typeID) {
          return false
        }
        return true
      }
      if (this.activeStep === 'questions') {
        if (Object.keys(this.typeData.questionAnswers).length !== this.typeQuestions.length) {
          return false
        }
        return true
      }
      if (this.activeStep === 'amount') {
        if (!this.typeData.amount.isSkipped && !this.typeData.amount.type) {
          return false
        }
        if (this.typeData.amount.type && !this.typeData.amount.number) {
          return false
        }
        return true
      }
      return true
    },
    buttonText() {
      if (this.activeStep === 'amount' && this.typeSubQuestions.length) {
        return this.mixWB('NEXT')
      }

      if (this.activeStep === 'subQuestions') {
        return this.mixWB('SAVE')
      }

      return this.mixWB('NEXT')
    },
    showSaveButton() {
      if (this.activeStep === 'finished') {
        return true
      }

      return false
    },
    allMainTypes() {
      const allMainTypes = [this.typeData]
      this.autoTypes.forEach((autoType) => {
        if (autoType.answer === 'yes') {
          allMainTypes.push(autoType.type)
        }
      })
      return allMainTypes
    },
  },
  methods: {
    getClosePath() {
      return { name: 'ScreeningsUpdateCategory' }
    },
    onUnitsUpdate() {
      this.saveLastActiveStepNo(0)
    },
    onUnitNextsClick(selectedUnitIDs) {
      this.typeData.unitIDs = selectedUnitIDs
      this.onNextStep()
    },
    onImageListUpdate(imageList) {
      this.typeData.images = imageList
    },
    onTypeGroupClick() {
      if (!this.isNewType) {
        return
      }

      this.activeStepNo = 1
      this.lastActiveNo = 1
      this.typeData.type.typeID = null
      this.typeData.type.isOtherType = false
      this.typeData.type.otherType = ''
      this.typeData.type.otherTypeWDGID = null
    },
    onTypeClick(type) {
      if (!this.isNewType) {
        return
      }
      if (this.typeData.type.typeID === type.id) {
        return
      }

      this.activeStepNo = 1
      this.lastActiveNo = 1
      this.typeData.type.typeID = type.id
      this.typeData.type.isOtherType = false
      this.typeData.type.otherType = ''
      this.typeData.type.otherTypeWDGID = null

      this.resetCoating()
      this.resetUnderlining()
      this.resetQuestions()
      this.resetSubQuestions()
      this.resetAutoTypes()
      this.resetAmount()
      this.resetResale()

      requestAnimationFrame(() => {
        this.onNextStep()
      })
    },
    onOtherTypeClick() {
      if (!this.isNewType) {
        return
      }
      if (this.typeData.type.otherType) {
        return
      }

      this.activeStepNo = 1
      this.lastActiveNo = 1
      this.typeData.type.typeID = null
      this.typeData.type.isOtherType = true
      this.typeData.type.otherTypeWDGID = null

      this.resetCoating()
      this.resetQuestions()
      this.resetSubQuestions()
      this.resetAmount()
      this.resetResale()
    },
    onOtherTypeInputUpdated(value) {
      this.typeData.type.otherType = value
      this.saveLastActiveStepNo(1)
    },
    onOtherTypeSelected() {
      this.onNextStep()
    },
    onQuestionsUpdated() {
      this.saveLastActiveStepNo(2)
      this.scrollToLastSection()
    },
    onQuestionsAllAnswered(answers) {
      this.saveLastActiveStepNo(2)
      this.$set(this.typeData, 'questionAnswers', answers)

      this.onNextStep()
    },
    onGroupClick(wdgID) {
      this.typeData.type.otherTypeWDGID = wdgID
      this.saveLastActiveStepNo(3)
      this.onNextStep()
    },
    onSubQuestionsUpdated() {
      this.saveLastActiveStepNo(4)
      this.scrollToLastSection()
    },
    onSubQuestionsFinished(answers) {
      this.typeData.subQuestionAnswers = answers
      this.saveLastActiveStepNo(4)
      this.onNextStep()
    },
    onCoatingUpdated() {
      this.saveLastActiveStepNo(5)
      this.scrollToLastSection()
    },
    onCoatingFinished(coatingData) {
      this.$set(this.typeData, 'coating', coatingData)
      this.saveLastActiveStepNo(5)
      this.onNextStep()
    },
    onUnderliningUpdated() {
      this.saveLastActiveStepNo(6)
      this.scrollToLastSection()
    },
    onUnderliningFinished(underliningData) {
      this.$set(this.typeData, 'underlining', underliningData)
      this.saveLastActiveStepNo(6)
      this.onNextStep()
    },
    onShowAutoTypesStatus(status) {
      this.showAutoTypes = status
      this.areAutoTypesLoaded = true
    },
    onAutoTypeUpdate() {
      this.saveLastActiveStepNo(7)
      this.scrollToLastSection()
    },
    onAutoTypesFinished(autoTypes) {
      this.autoTypes = autoTypes
      this.saveLastActiveStepNo(7)
      this.onNextStep()
    },
    onAmountTypeSelected(option) {
      this.saveLastActiveStepNo(8)

      if (option === 'skip') {
        this.typeData.amount.isSkipped = true
        this.typeData.amount.type = null
        this.typeData.amount.number = null
        this.onNextStep()
        return
      }

      this.typeData.amount.isSkipped = false
    },
    onAmountInputUpdated(number) {
      this.typeData.amount.number = number
    },
    onAmountSelected({ type, number }) {
      this.typeData.amount.isSkipped = false
      this.typeData.amount.type = type
      this.typeData.amount.number = number

      this.onNextStep()
    },
    onResaleUpdate(value) {
      this.typeData.resale = value
      this.onNextStep()
    },
    onInterConnectionDataUpdate({ name, value }) {
      this[name] = value
    },
    onExistsOnItsOwnUpdate() {
      this.typeData.isOwnTypeAsWell = !this.typeData.isOwnTypeAsWell
    },
    onPlaceUpdate(value) {
      this.typeData.places = value
    },
    onDescriptionUpdate(value) {
      this.typeData.description = value
    },
    saveLastActiveStepNo(editedStepNumber) {
      if (this.activeStepNo > editedStepNumber) {
        this.lastActiveNo = this.activeStepNo
      }
      this.activeStepNo = editedStepNumber
    },
    onNextStep() {
      if (this.isLoading) {
        return
      }

      this.scrollToLastSection()

      if (this.lastActiveNo > this.activeStepNo) {
        this.setActiveStepNo(this.lastActiveNo)
        return
      }

      this.lastActiveNo = 0

      // Step - Type and images
      if (this.activeStepNo < 1) {
        this.setActiveStepNo(1)
        return
      }

      // Step - Questions
      if (this.activeStepNo < 2 && this.typeQuestions.length) {
        this.setActiveStepNo(2)
        return
      }

      // Step - Work description group
      if (this.activeStepNo < 3 && this.typeData.type.isOtherType) {
        this.setActiveStepNo(3)
        return
      }

      // Step - Sub questions
      if (this.activeStepNo < 4 && this.typeSubQuestions.length) {
        this.setActiveStepNo(4)
        return
      }

      // Step - Coating
      if (this.activeStepNo < 5 && this.showCoatingSelect) {
        this.setActiveStepNo(5)
        return
      }

      // Step - Underlining
      if (this.activeStepNo < 6 && this.selectedCategoryType.askForUnderlining) {
        this.setActiveStepNo(6)
        return
      }

      // Step - Auto types
      if (this.activeStepNo < 7 && this.showAutoTypes) {
        this.setActiveStepNo(7)
        return
      }

      // Step - Amount
      if (this.activeStepNo < 8 && this.metaData.doWasteReview) {
        this.setActiveStepNo(8)
        return
      }

      // Find samples
      this.findSamples()

      // Step - Resale
      if (this.activeStepNo < 9 && this.showResaleSelect) {
        this.setActiveStepNo(9)
        return
      }

      this.setActiveStepNo(10)
    },
    setActiveStepNo(number) {
      this.findTags()
      this.activeStepNo = number
    },
    resetCoating() {
      this.typeData.resetCoating()
      this.resetCoatingCounter += 1
    },
    resetUnderlining() {
      this.typeData.resetUnderlining()
      this.resetUnderliningCounter += 1
    },
    resetQuestions() {
      this.typeData.questionAnswers = {}
      this.resetQuestionsCounter += 1
    },
    resetSubQuestions() {
      this.typeData.subQuestionAnswers = {}
      this.resetSubQuestionsCounter += 1
    },
    resetAutoTypes() {
      EventBus.$emit('auto-type-reset')
      this.autoTypes = []
    },
    resetAmount() {
      this.typeData.amount.isSkipped = false
      this.typeData.amount.type = null
      this.typeData.amount.number = null
      this.resetAmountCounter += 1

      if (!this.metaData.doWasteReview) {
        this.typeData.amount.isSkipped = true
      }
    },
    resetResale() {
      this.typeData.resale = ''
      this.resetResaleCounter += 1
    },
    findTags() {
      const tags = findTags({
        mainQuestions: this.typeQuestions,
        mainQuestionAnswers: this.typeData.questionAnswers,
        subQuestions: this.categoryTypeSubQuestions,
        subQuestionAnswers: this.typeData.subQuestionAnswers,
        autoTypes: this.autoTypes,
      })

      this.typeData.tags = tags
    },
    findSamples() {
      // Get samples and assessments
      const {
        samples,
        assessments,
      } = findSamples({
        mainQuestionAnswers: this.typeData.questionAnswers,
        selectedCategoryType: this.selectedCategoryType,
        subQuestionAnswers: this.typeData.subQuestionAnswers,
        categoryTypeSubQuestions: this.categoryTypeSubQuestions,
        isOtherType: this.typeData.type.isOtherType,
        typeQuestions: this.typeQuestions,
      })

      this.typeData.samples = samples
      this.typeData.assessments = assessments
    },
    onSaveButtonClick() {
      this.saveType()
    },
    saveType() {
      if (this.isLoading) {
        return
      }

      // Check for images
      if (!this.typeData.images.length) {
        scrollTo({
          element: this.$refs.ImageSelect.$el,
          offset: -50,
        })
        EventBus.$emit('missing-images', { groupName: 'main-type' })
        return
      }

      // Check for resale
      if (!this.typeData.resale && this.showResaleSelect) {
        scrollTo({
          element: this.$refs.ResaleSelect.$el,
          offset: -50,
        })
        EventBus.$emit('missing-resale')
        return
      }

      // Validate inter connections
      let allInterConnectionsGood = true
      this.addedInterConnections.forEach((interConnection) => {
        const isSplitStatusOK = !!interConnection.splitStatus
        const isEffectDirectionOK = interConnection.splitStatus === 'stuck'
          || (interConnection.splitStatus === 'splitable' && interConnection.effectDirection)

        if (!isSplitStatusOK || !isEffectDirectionOK) {
          EventBus.$emit('invalid-inter-connection', {
            isSplitStatusOK,
            isEffectDirectionOK,
            groupID: interConnection.groupID,
            scrollToHere: allInterConnectionsGood,
          })
          allInterConnectionsGood = false
        }
      })

      if (!allInterConnectionsGood) {
        return
      }

      this.isLoading = true

      this.findTags()
      this.findSamples()
      this.setExistsOnItsOwnState()

      // Save type
      this.$store.dispatch('setScreeningType', {
        type: this.typeData,
        isNew: this.isNewType,
      })

      // Handle auto types
      this.$store.dispatch('handleAutoTypes', {
        mainType: this.typeData,
        autoTypes: JSON.parse(JSON.stringify(this.autoTypes)),
      })

      // Update exist on their own status for connected types
      this.updateExistOnTheirOwnForConnectedTypes()

      // Update inter connections
      this.updateInterConnections()

      // Check to remove auto assessed sample
      this.checkToRemoveAutoAssessedSample()

      // Ensure all sample markings has correct units, if unit have changed they should be reset.
      this.updateSampleMarkings()

      this.$router.push({
        name: 'ScreeningsUpdateCategory',
        params: {
          categoryID: this.$route.query.from || this.currentCategory.id,
        },
      })
    },
    setExistsOnItsOwnState() {
      if (this.addedInterConnections.length && this.typeData.isOwnTypeAsWell === null) {
        this.typeData.isOwnTypeAsWell = false
      }
    },
    updateExistOnTheirOwnForConnectedTypes() {
      Object.keys(this.typesExistsOnTheirOwnAnswers).forEach((typeID) => {
        const type = this.screeningTypes.find((x) => x.id === typeID)
        if (!type || !this.typesExistsOnTheirOwnAnswers[typeID]) {
          return
        }
        type.isOwnTypeAsWell = true
        this.$store.dispatch('setScreeningType', { type })
      })
    },
    updateInterConnections() {
      // Remove inter connections
      this.removedInterConnectionIDs.forEach((interConnectionID) => {
        this.$store.dispatch('deleteInterConnection', interConnectionID)
      })

      // Add or update inter connections
      this.addedInterConnections.forEach((interConnection) => {
        this.$store.dispatch('setScreeningInterConnection', interConnection)
      })

      // Create copies of existing groups
      this.existingInterConnectionGroupsToCopy.forEach((group) => {
        group.interConnections.forEach((interConnection) => {
          this.$store.dispatch('setScreeningInterConnection', interConnection)
        })
      })
    },
    checkToRemoveAutoAssessedSample() {
      if (!this.hadAutoAssessmentsOnLoad && !this.typeData.assessments.assessmentIDs.length) {
        return
      }

      // Find existing sample
      const existingMaterialSample = this.screeningSamples.find(
        (x) => x.typeID === this.typeData.id && x.kindOfSample === 'material',
      )

      // Conclude item is already there
      if (existingMaterialSample && this.typeData.assessments.assessmentIDs.length) {
        return
      }

      // Remove unneeded sample
      if (existingMaterialSample && !this.typeData.assessments.assessmentIDs.length) {
        this.$store.dispatch('deleteScreeningSample', existingMaterialSample)
      }

      // Add new sample
      if (!existingMaterialSample && this.typeData.assessments.assessmentIDs.length) {
        const sample = new Sample({
          typeID: this.typeData.id,
          kindOfSample: 'material',
          isAutoAssessment: true,
          status: 'auto-assess-asbestos-perforated-ceiling-tiles',
          assessNumber: this.nextAssessNumber,
        })

        this.$store.dispatch('setScreeningSample', { sample, isNew: true })
      }
    },
    updateSampleMarkings() {
      const { unitIDs } = this.typeData

      this.screeningSamples.forEach((sample) => {
        const sampleFloorPlanUnitID = sample.floorPlanUnit

        if (sample.typeID !== this.typeData.id
          || !sampleFloorPlanUnitID
          || unitIDs.includes(sampleFloorPlanUnitID)) {
          return
        }

        // Check if the floorplanUnit is on any parent of this types units.
        const hasParentUnitWithSampleFloorPlan = Unit.getUnitsByIds(unitIDs).some(
          (unit) => unit.parentsWithFloorPlan().includes(sampleFloorPlanUnitID),
        )
        if (hasParentUnitWithSampleFloorPlan) {
          return
        }

        // The sample is marked on a unit that does not lie on this type anymore. Edge case where
        // a unit which the sample was marked on, was removed.
        sample.resetFloorPlan()
        sample.status = 'not-done'

        this.$store.dispatch('setScreeningSample', { sample })
      })
    },
    scrollToLastSection() {
      if (!this.isScrolledAllowed) {
        return
      }

      requestAnimationFrame(() => {
        scrollTo({
          element: this.$refs.mainWrap,
          offset: 100,
          goToBottom: true,
        })
      })
    },
    setNewTypeOnLoad() {
      const type = new Type({})

      // Set categoryID
      type.categoryID = this.$route.params.categoryID

      this.$set(this, 'typeData', type)
      this.isScrolledAllowed = true

      requestAnimationFrame(() => {
        EventBus.$emit('load-new-type')
      })
    },
    setEditTypeOnLoad() {
      const savedType = this.screeningTypes.find(
        (x) => x.id === this.$route.params.addedTypeID,
      )

      this.activeStepNo = this.roadMap.length - 1

      const type = new Type({
        ...JSON.parse(JSON.stringify(savedType)),
      })

      this.typeData = type

      // Auto assessments
      this.hadAutoAssessmentsOnLoad = !!this.typeData.assessments.assessmentIDs.length

      // Send data to sections
      requestAnimationFrame(() => {
        EventBus.$emit('load-type-edit', this.typeData)
        EventBus.$emit('load-type-images', { images: this.typeData.images, groupName: 'main-type' })
        EventBus.$emit('load-type-coating', { coating: this.typeData.coating, groupName: 'main-type' })
        EventBus.$emit('load-type-underlining', { underlining: this.typeData.underlining, groupName: 'main-type' })
      })
    },
  },
  components: {
    AmountSelect,
    Button,
    CloseButton,
    QuestionsSelect,
    SubQuestionsSelect,
    CoatingSelect,
    TypeSelect,
    ImageSelect,
    ResaleSelect,
    PlacesSelect,
    InterConnectionsSelect,
    Description,
    WorkDescriptionGroupSelect,
    UnitSelect,
    AutoTypesSelect,
    UnderlineSelect,
  },
  created() {
    if (this.isNewType) {
      this.setNewTypeOnLoad()
    }
    else {
      this.setEditTypeOnLoad()
    }
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateCategoryAddType
    pageWrap()

  .CategoryTitle
    display block
    font-size 1.125rem
    font-weight bold
    text-transform uppercase
    margin-bottom 20px
</style>
