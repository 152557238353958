<template>
  <div class="QuestionModal">
    <span
      v-if="title"
      class="Title"
      v-html="title"/>
    <span
      class="Question"
      v-html="question"/>

    <div class="ButtonsWrap">
      <Button
        :text="mixWB('YES')"
        :outlined="isYesOutlined"
        @button-click="onAnswerClick(true)"/>
      <Button
        :text="mixWB('NO')"
        :outlined="isNoOutlined"
        @button-click="onAnswerClick(false)"/>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'QuestionModal',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    question: {
      type: String,
      required: true,
    },
    questionID: {
      type: String,
      required: true,
    },
    additionalData: {
      type: Object,
      required: false,
      default: () => {},
    },
    isYesOutlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNoOutlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onAnswerClick(answer) {
      this.$emit('pass-to-parent', {
        name: 'answer',
        value: {
          answer,
          questionID: this.questionID,
          additionalData: this.additionalData,
        },
      })
    },
  },
  components: {
    Button,
  },

}
</script>

<style lang="stylus" scoped>
  .QuestionModal
    display block
    padding-top 15px

  .Title
    text-align center
    font-size 1.5rem
    font-weight bold
    padding 0px 20px 30px

  .Question
    text-align center
    font-size 1.25rem
    padding 0px 20px 30px

  .ButtonsWrap
    display flex
    width 100%
    >>> .ButtonWrap
      flex-grow 2
      &:first-child
        width 20%
        margin-right 10px
      &:last-child
        width 80%
</style>
