<template>
  <div class="PlaceSelectDialog">
    <span class="Title">{{ mixWB('CHOOSE_ROOMS') }}</span>

    <div class="ListWrap">
      <!-- Defined places -->
      <div
        v-for="place in mutatedPlacesAsArray"
        :key="place.id"
        class="PlaceItem"
        :class="{ Selected: selectedPlaces.placeIDs.includes(place.id)}"
        @click="onPlaceClick(place.id)">
        {{ place.name }}
      </div>

      <!-- Other places -->
      <div
        v-for="(place) in mutatedOtherPlaces"
        :key="place.text"
        :class="{ Selected: place.isSelected}"
        class="PlaceItem"
        @click="onOtherTypeClick(place)">
        {{ place.text }}
      </div>
    </div>

    <div class="OtherPlacesWrap">
      <InputField
        name="otherPlace"
        :placeholder="mixWB('ENTER_ANOTHER_LOCATION')"
        :value="otherPlace"
        @on-update="onOtherPlaceUpdate"
        @enter="onAddAnotherClick" />
      <Button
        :text="mixWB('ADD')"
        :fullWidth="false"
        :isDisabled="!this.otherPlace"
        @button-click="onAddAnotherClick" />
    </div>

    <Button
      :text="mixWB('DONE')"
      @button-click="onDoneClick" />
  </div>
</template>

<script>
import { sortBy, union } from 'lodash-es'
import Button from '@/components/Buttons/Button.vue'
import InputField from '@/components/FormElements/InputField'
import { mapGetters } from 'vuex'

export default {
  name: 'PlaceSelectDialog',
  props: {
    startingPlaces: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPlaces: {
        placeIDs: [],
        otherPlaces: [],
      },
      savedOtherPlaces: [],
      otherPlace: '',
    }
  },
  computed: {
    ...mapGetters([
      'placesAsArray',
      'currentScreening',
    ]),
    mutatedPlacesAsArray() {
      const array = this.placesAsArray.map((place) => {
        place.name = this.mixWB(place.wordbook)
        return place
      })

      return sortBy(array, ['name'])
    },
    mutatedOtherPlaces() {
      const allOtherPlaces = union(this.selectedPlaces.otherPlaces, this.savedOtherPlaces)

      return sortBy(allOtherPlaces.map((x) => ({
        text: x,
        isSelected: this.selectedPlaces.otherPlaces.includes(x),
      })), ['text'])
    },
  },
  methods: {
    onPlaceClick(placeID) {
      const index = this.selectedPlaces.placeIDs.findIndex((x) => x === placeID)
      if (index > -1) {
        this.selectedPlaces.placeIDs.splice(index, 1)
        return
      }
      this.selectedPlaces.placeIDs.push(placeID)
    },
    onOtherPlaceUpdate({ value }) {
      this.otherPlace = value
    },
    onAddAnotherClick() {
      if (!this.otherPlace) {
        return
      }

      const index = this.selectedPlaces.otherPlaces.findIndex(
        (x) => x.toLowerCase() === this.otherPlace.toLowerCase(),
      )

      if (index > -1) {
        this.otherPlace = ''
        return
      }

      this.selectedPlaces.otherPlaces.push(this.otherPlace)

      this.saveOtherPlaceToSessionStorage(this.otherPlace)

      this.otherPlace = ''
    },
    onOtherTypeClick(place) {
      if (!place.isSelected) {
        this.selectedPlaces.otherPlaces.push(place.text)
        return
      }

      const index = this.selectedPlaces.otherPlaces.findIndex((x) => x === place.text)
      this.selectedPlaces.otherPlaces.splice(index, 1)
    },
    onDoneClick() {
      this.selectedPlaces.placeIDs.sort()

      this.$emit('pass-to-parent', {
        name: 'place-select',
        value: this.selectedPlaces,
      })
    },
    saveOtherPlaceToSessionStorage(otherPlace) {
      const savedOtherPlacesString = window.sessionStorage.getItem('saved-other-places')
      const savedOtherPlacesObject = savedOtherPlacesString
        ? JSON.parse(savedOtherPlacesString)
        : {}

      let savedPlaces = []
      if (savedOtherPlacesObject[this.currentScreening.id]) {
        savedPlaces = savedOtherPlacesObject[this.currentScreening.id]
      }

      savedPlaces = union(savedPlaces, [otherPlace])
      savedOtherPlacesObject[this.currentScreening.id] = savedPlaces
      this.savedOtherPlaces = savedPlaces

      window.sessionStorage.setItem('saved-other-places', JSON.stringify(savedOtherPlacesObject))
    },
    setPlacesOnLoad() {
      this.selectedPlaces = JSON.parse(JSON.stringify(this.startingPlaces))
    },
    getSavedOtherPlacesFromSessionStorage() {
      const savedOtherPlacesString = window.sessionStorage.getItem('saved-other-places')
      const savedOtherPlacesObject = savedOtherPlacesString
        ? JSON.parse(savedOtherPlacesString)
        : {}

      const savedPlaces = savedOtherPlacesObject[this.currentScreening.id]
      if (!savedPlaces) {
        return
      }

      this.savedOtherPlaces = savedPlaces
    },
  },
  components: {
    Button,
    InputField,
  },
  mounted() {
    this.setPlacesOnLoad()
    this.getSavedOtherPlacesFromSessionStorage()
  },
}
</script>

<style lang="stylus" scoped>
  .PlaceSelectDialog
    span
      display block

  .Title
    modalTitle()

  .ListWrap
    display flex
    flex-wrap wrap
    margin 0 -2.5px 10px

  .OtherPlacesWrap
    display flex
    margin-bottom 15px
    > div, > button
      margin-bottom 0
      margin-right 10px
      &:last-child
        flex-shrink 3
        margin-right 0

  .PlaceItem
    flex-center-children()
    min-height 40px
    min-width 60px
    padding 5px 10px
    margin 0px 2.5px 5px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
    &.Selected
      background-color $color_primary
      border 1px solid lighten($color_primary, 25%)
      color #fff
</style>
