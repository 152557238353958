<template>
  <div class="Description">
    <SectionHeadline :text="mixWB('NOTE')" />

    <InputField
      name="description"
      :value="description"
      :placeholder="mixWB('NOTE_FOR_THE_REPORT')"
      :disableAutoSuggest="true"
      @blur="onFieldBlur" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline'
import InputField from '@/components/FormElements/InputField'
import EventBus from '@/EventBus'

export default {
  name: 'Description',
  data() {
    return {
      description: '',
    }
  },
  methods: {
    onFieldBlur({ value }) {
      this.$emit('description-update', value)
    },
    onTypeLoad({ description }) {
      this.description = description
    },
  },
  components: {
    SectionHeadline,
    InputField,
  },
  created() {
    EventBus.$on('load-type-edit', this.onTypeLoad)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .Description
    padding-top 10px
    padding-bottom 10px
</style>
