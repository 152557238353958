<template>
  <div class="InterConnectionsSelect">
    <SectionHeadline :text="mixWB('SITTING_TOGETHER_WITH_INSIDE_AND_OUT')" />

    <!-- Current inter connection groups -->
    <InterConnectionRow
      v-for="group in interConnectionGroups"
      :key="group.id"
      :interConnectionGroupID="group.groupID"
      :typeItems="group.typeItems"
      :typeData="typeData"
      :autoTypes="autoTypes"
      :allInterConnections="allInterConnections"
      :allScreeningTypes="allScreeningTypes"
      @no-type-selected="onNoTypeSelected"
      @inter-connection-select="onInterConnectionSelect" />

    <!-- New inter connection group row -->
    <InterConnectionRow
      v-if="!activeAutoTypes.length"
      :typeData="typeData"
      :autoTypes="autoTypes"
      :allInterConnections="allInterConnections"
      :allScreeningTypes="allScreeningTypes"
      @inter-connection-select="onInterConnectionSelect" />

    <!-- Ask if type is own type as well -->
    <div
      class="ExistsOnItOwnWrap"
      v-if="interConnectionGroups.length && !this.categoryType.isLeveling">
      <Checkbox
        name="exists-on-its-own"
        :text="mixWB(
          'IS_X_ALSO_FOUND_AS_ITS_OWN_TYPE_TEXT',
          [typeData.getTitle(true)]
        )"
        :isSelected="!!typeData.isOwnTypeAsWell"
        @checkbox-click="onCheckBoxClick" />
    </div>

    <!-- Dialogs -->
    <Dialog
      :isShowing="nextQuestion.showQuestion || false"
      :useComponent="QuestionModal"
      :easyClose="false"
      :componentProps="{
        title: nextQuestion.questionTitle,
        question: nextQuestion.question || '',
        questionID: nextQuestion.id,
        additionalData: nextQuestion.additionalData,
        isYesOutlined: true,
      }"
      @answer="onQuestionAnswer"
      @close="onQuestionClose" />

    <Dialog
      :isShowing="showNotificationModal"
      :useComponent="NoficationModal"
      :easyClose="false"
      :componentProps="{
        texts: notificationTexts,
      }"
      @close="onNotificationClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DB_INTER_CONNECTIONS, setIDOnCreate } from '@/globals/javascript/models/_helper'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import InterConnectionRow from '@/components/ScreeningTypeSections/InterConnections/InterConnectionRow.vue'
import EventBus from '@/EventBus'
import InterConnection from '@/globals/javascript/models/InterConnection'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import QuestionModal from '@/components/Modals/QuestionModal.vue'
import NoficationModal from '@/components/Modals/NoficationModal.vue'
import { union } from 'lodash-es'
import Type from '@/globals/javascript/models/Type'

export default {
  name: 'InterConnectionsSelect',
  props: {
    typeData: {
      type: Object,
      required: true,
    },
    autoTypes: {
      type: Array,
      required: true,
    },
    categoryType: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      removedInterConnectionIDs: [],
      addedInterConnections: [],
      typesExistsOnTheirOwnAnswers: {},
      existingInterConnectionGroupsToCopy: [],

      // Question modal props
      QuestionModal,
      questions: [
        {
          id: 'type-exists-on-its-own',
          questionTitle: this.mixWB('W: Egen type?'),
          question: '',
          showQuestion: false,
          additionalData: {
            questionTypeID: '',
          },
        },
        {
          id: 'group-exists-on-its-own',
          questionTitle: this.mixWB('W: Ny sammenkobling?'),
          question: '',
          showQuestion: false,
          isQuestionAwaitingResolve: false,
          unresolvedAnswer: null,
        },
      ],
      // Notificatin modal
      showNotificationModal: false,
      NoficationModal,
      notificationTexts: [],
    }
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'screeningInterConnections',
      'categoryTypes',
    ]),
    activeAutoTypes() {
      return this.autoTypes.filter((x) => x.answer === 'yes')
    },
    nextQuestion() {
      return this.questions.find((x) => x.showQuestion) || {}
    },
    allInterConnections() {
      const combined = this.screeningInterConnections.concat(
        this.addedInterConnections,
      )

      return combined.filter((x) => !this.removedInterConnectionIDs.includes(x.id))
    },
    allScreeningTypes() {
      const usedIDs = []
      const combined = []

      // Add main type
      combined.push(this.typeData)
      usedIDs.push(this.typeData.id)

      // Add auto types
      this.activeAutoTypes.forEach((autoType) => {
        combined.push(autoType.type)
        usedIDs.push(autoType.type.id)
      })

      // Add rest
      this.screeningTypes.forEach((type) => {
        if (usedIDs.includes(type.id)) {
          return
        }
        combined.push(type)
      })

      return combined
    },
    interConnectionGroups() {
      const allInterConnectionsForType = this.allInterConnections.filter(
        (x) => x.outerTypeID === this.typeData.id
          || x.innerTypeID === this.typeData.id,
      )

      const usedInterConnectionGroupIDs = []
      const groupedTypes = []
      allInterConnectionsForType.forEach((interConnection) => {
        if (usedInterConnectionGroupIDs.includes(interConnection.groupID)) {
          return
        }
        usedInterConnectionGroupIDs.push(interConnection.groupID)
        const groupData = {
          groupID: interConnection.groupID,
          typeItems: [{
            type: this.typeData,
            innerInterConnection: null,
            outerInterConnection: null,
          }],
        }

        // Find all inner connections
        let nextInnerItem = this.allInterConnections.find(
          (x) => x.outerTypeID === this.typeData.id
            && x.groupID === interConnection.groupID,
        )
        while (nextInnerItem) {
          groupData.typeItems[0].innerInterConnection = nextInnerItem
          groupData.typeItems.unshift({
            // eslint-disable-next-line no-loop-func
            type: this.allScreeningTypes.find((x) => x.id === nextInnerItem.innerTypeID),
            innerInterConnection: null,
            outerInterConnection: nextInnerItem,
          })
          nextInnerItem = this.allInterConnections.find(
            // eslint-disable-next-line no-loop-func
            (x) => x.outerTypeID === nextInnerItem.innerTypeID
              && x.groupID === interConnection.groupID,
          )
        }

        // Find all outer connections
        let nextOuterItem = this.allInterConnections.find(
          (x) => x.innerTypeID === this.typeData.id
            && x.groupID === interConnection.groupID,
        )
        while (nextOuterItem) {
          groupData.typeItems[groupData.typeItems.length - 1].outerInterConnection = nextOuterItem
          groupData.typeItems.push({
            // eslint-disable-next-line no-loop-func
            type: this.allScreeningTypes.find((x) => x.id === nextOuterItem.outerTypeID),
            innerInterConnection: nextOuterItem,
            outerInterConnection: null,
          })
          nextOuterItem = this.allInterConnections.find(
            // eslint-disable-next-line no-loop-func
            (x) => x.innerTypeID === nextOuterItem.outerTypeID
              && x.groupID === interConnection.groupID,
          )
        }
        groupedTypes.push(groupData)
      })

      return groupedTypes
    },
  },
  watch: {
    activeAutoTypes(to) {
      // Remove all previous connections
      this.removedInterConnectionIDs = []
      this.addedInterConnections = []

      // Add any new inter connections
      const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
      let lastType = this.typeData
      to.forEach((autoType) => {
        if (autoType.answer === 'no') {
          return
        }

        const newInterConnection = new InterConnection({
          groupID: newGroupID,
          innerTypeID: lastType.id,
          outerTypeID: autoType.type.id,
        })

        this.addedInterConnections.push(newInterConnection)

        // Check to specify split status
        this.checkToAutoSetSplitStatus({
          interConnection: newInterConnection,
        })
        lastType = autoType.type
      })
    },
    addedInterConnections() {
      this.$emit('data-update', {
        name: 'addedInterConnections',
        value: this.addedInterConnections,
      })
    },
    removedInterConnectionIDs() {
      this.$emit('data-update', {
        name: 'removedInterConnectionIDs',
        value: this.removedInterConnectionIDs,
      })

      // Update added connections
      this.addedInterConnections = this.addedInterConnections.filter(
        (x) => !this.removedInterConnectionIDs.includes(x.id),
      )
    },
    typesExistsOnTheirOwnAnswers() {
      this.$emit('data-update', {
        name: 'typesExistsOnTheirOwnAnswers',
        value: this.typesExistsOnTheirOwnAnswers,
      })
    },
    existingInterConnectionGroupsToCopy() {
      this.$emit('data-update', {
        name: 'existingInterConnectionGroupsToCopy',
        value: this.existingInterConnectionGroupsToCopy,
      })
    },
  },
  methods: {
    getConnectionValidStatus({
      rowInterConnectionGroupID,
      interConnectionGroupID,
      typeID,
      side,
    }) {
      let foundTypeIDs = []
      let foundDuplicateTypeIDs = []
      // Find type ids for existing row
      if (rowInterConnectionGroupID) {
        const interConnections = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID: this.typeData.id,
          interConnectionGroupID: rowInterConnectionGroupID,
          side: side === 'inner' ? 'outer' : 'inner',
          interConnections: this.allInterConnections,
        })
        interConnections.forEach((interConnection) => {
          if (interConnection.innerTypeID) {
            foundTypeIDs = union(foundTypeIDs, [interConnection.innerTypeID])
          }
          if (interConnection.outerTypeID) {
            foundTypeIDs = union(foundTypeIDs, [interConnection.outerTypeID])
          }
        })
      }

      // Find type ids for selected inter connection group
      if (interConnectionGroupID) {
        const interConnections = this.allInterConnections.filter(
          (x) => x.groupID === interConnectionGroupID,
        )
        let foundGroupTypeIDs = []
        interConnections.forEach((interConnection) => {
          if (interConnection.innerTypeID) {
            foundGroupTypeIDs = union(foundGroupTypeIDs, [interConnection.innerTypeID])
          }
          if (interConnection.outerTypeID) {
            foundGroupTypeIDs = union(foundGroupTypeIDs, [interConnection.outerTypeID])
          }
        })
        foundGroupTypeIDs.forEach((typeID) => {
          if (foundTypeIDs.includes(typeID)) {
            foundDuplicateTypeIDs = union(foundDuplicateTypeIDs, [typeID])
          }
          else {
            foundTypeIDs.push(typeID)
          }
        })
      }

      if (!interConnectionGroupID) {
        if (foundTypeIDs.includes(typeID)) {
          foundDuplicateTypeIDs.push(typeID)
        }
        foundTypeIDs.push(typeID)
      }

      // Show notification to user
      if (foundDuplicateTypeIDs.length) {
        const typeNameList = foundDuplicateTypeIDs.map((typeID) => {
          const type = this.allScreeningTypes.find((x) => x.id === typeID)
          return type.getTitle().toLowerCase()
        })
        this.notificationTexts.push(this.mixWB('THIS_INTERCONNECTION_COULD_NOT_BE_FORMED'))
        if (foundDuplicateTypeIDs.length === 1) {
          this.notificationTexts.push(this.mixWB('TYPE_X_WOULD_APPEAR_SEVERAL_TIMES_IN_INTERCONNECTION', [typeNameList.join(', ')]))
        }
        else {
          this.notificationTexts.push(this.mixWB('TYPES_X_WOULD_APPEAR_SEVERAL_TIMES_IN_INTERCONNECTION', [typeNameList.join(', ')]))
        }
        this.showNotificationModal = true
      }
      return !foundDuplicateTypeIDs.length
    },
    getExistingConnection({
      side,
      rowInterConnectionGroupID,
    }) {
      if (side === 'inner') {
        return this.allInterConnections.find(
          (x) => x.groupID === rowInterConnectionGroupID
            && x.outerTypeID === this.typeData.id,
        )
      }
      if (side === 'outer') {
        return this.allInterConnections.find(
          (x) => x.groupID === rowInterConnectionGroupID
            && x.innerTypeID === this.typeData.id,
        )
      }

      return false
    },
    async onInterConnectionSelect({
      side,
      choice,
      interConnectionGroupID,
      rowInterConnectionGroupID,
      typeID,
      isFirstInGroup,
      isLastInGroup,
    }) {
      // Step 1 - Check that comming connection is valid
      const isCommingConnectionValid = this.getConnectionValidStatus({
        rowInterConnectionGroupID,
        interConnectionGroupID,
        typeID,
        side,
      })
      if (!isCommingConnectionValid) {
        return
      }

      // Step 2 - Add questions
      // - Add question: Does selected type exist on its own
      const ownTypeQuestion = this.questions.find((x) => x.id === 'type-exists-on-its-own')
      const type = this.screeningTypes.find((x) => x.id === typeID)
      if (
        type.isOwnTypeAsWell === null
        && !this.typesExistsOnTheirOwnAnswers.hasOwnProperty(type.id)
      ) {
        ownTypeQuestion.question = this.mixWB(
          'IS_X_ALSO_FOUND_AS_ITS_OWN_TYPE_TEXT', [type.getTitle(true).toLowerCase()],
        )
        ownTypeQuestion.additionalData.questionTypeID = type.id
        ownTypeQuestion.showQuestion = true
      }

      // - Add question: Does entire existing connection group exist on its own?
      const ownGroupQuestion = this.questions.find((x) => x.id === 'group-exists-on-its-own')
      if (
        interConnectionGroupID
        && choice === 'all'
        && ((side === 'inner' && isLastInGroup) || (side === 'outer' && isFirstInGroup))
      ) {
        ownGroupQuestion.showQuestion = true
        ownGroupQuestion.isQuestionAwaitingResolve = true
        ownGroupQuestion.unresolvedAnswer = null
        ownGroupQuestion.question = this.mixWB(
          'IS_THIS_INTERCONNECTION_ALSO_FOUND_WITHOUT_X',
          [this.typeData.getTitle().toLowerCase()],
        )
      }

      // Step 3 - Wait for all questions to be answered
      await new Promise((resolve) => {
        const waitForQuestionsAnswered = setInterval(() => {
          if (Object.keys(this.nextQuestion).length === 0) {
            resolve()
            clearInterval(waitForQuestionsAnswered)
          }
        }, 100)
      })

      // - Get question answers
      let doesGroupExistOnItsOwn = false
      if (ownGroupQuestion.isQuestionAwaitingResolve) {
        ownGroupQuestion.isQuestionAwaitingResolve = false
        doesGroupExistOnItsOwn = !!ownGroupQuestion.unresolvedAnswer
      }

      // Step 4 - Do actions based on all known data and answers

      // - Remove existing connection
      this.onTypeReplaced({
        side,
        rowInterConnectionGroupID,
      })

      // - Make copy of existing group
      if (doesGroupExistOnItsOwn) {
        this.makeCopyOfExistingGroup({
          interConnectionGroupID,
        })
      }

      // - Single choice
      if (choice === 'single') {
        this.addSingleType({
          side,
          typeID,
          interConnectionGroupID,
          rowInterConnectionGroupID,
        })
        return
      }

      // - All choice
      this.addAllTypes({
        side,
        typeID,
        interConnectionGroupID,
        rowInterConnectionGroupID,
        isFirstInGroup,
        isLastInGroup,
      })
    },
    makeCopyOfExistingGroup({
      interConnectionGroupID,
    }) {
      // Make copy of existing selected group
      let existingInterConnectionsCopy = []
      if (interConnectionGroupID) {
        existingInterConnectionsCopy = this.allInterConnections.filter(
          (x) => x.groupID === interConnectionGroupID,
        )
      }
      const data = {
        originalGroupID: interConnectionGroupID,
        interConnections: [],
      }
      const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
      existingInterConnectionsCopy.forEach((interConnection) => {
        data.interConnections.push(new InterConnection({
          ...interConnection,
          groupID: newGroupID,
          id: null,
        }))
      })
      this.existingInterConnectionGroupsToCopy.push(data)
    },
    addSingleType({
      side,
      typeID,
      rowInterConnectionGroupID,
    }) {
      const newInterConnection = new InterConnection({
        groupID: rowInterConnectionGroupID || '',
      })

      if (side === 'inner') {
        newInterConnection.innerTypeID = typeID
        newInterConnection.outerTypeID = this.typeData.id
      }
      if (side === 'outer') {
        newInterConnection.innerTypeID = this.typeData.id
        newInterConnection.outerTypeID = typeID
      }

      this.addedInterConnections.push(newInterConnection)

      // Check to specify split status
      this.checkToAutoSetSplitStatus({
        side,
        typeID,
        interConnection: newInterConnection,
      })
    },
    addAllTypes({
      side,
      typeID,
      interConnectionGroupID,
      rowInterConnectionGroupID,
      isFirstInGroup,
      isLastInGroup,
    }) {
      let newInterConnection = null
      const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)

      // Add to entire inner group
      if (side === 'inner' && isLastInGroup) {
        newInterConnection = new InterConnection({
          groupID: interConnectionGroupID,
          innerTypeID: typeID,
          outerTypeID: this.typeData.id,
        })
        this.addedInterConnections.push(newInterConnection)
      }

      // Add to apart of the inner group
      else if (side === 'inner' && !isLastInGroup) {
        const interConnectionGroup = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID,
          interConnectionGroupID,
          side,
          interConnections: this.allInterConnections,
        })
        newInterConnection = new InterConnection({
          groupID: newGroupID,
          innerTypeID: typeID,
          outerTypeID: this.typeData.id,
        })
        this.addedInterConnections.push(newInterConnection)
        interConnectionGroup.forEach((interConnection) => {
          this.addedInterConnections.push(new InterConnection({
            ...interConnection,
            id: '',
            groupID: newGroupID,
          }))
        })

        // Reset group ID from partly used group
        interConnectionGroupID = null
      }

      // Add to entire outer group
      if (side === 'outer' && isFirstInGroup) {
        newInterConnection = new InterConnection({
          groupID: interConnectionGroupID,
          innerTypeID: this.typeData.id,
          outerTypeID: typeID,
        })
        this.addedInterConnections.push(newInterConnection)
      }

      // Add to apart of the outer group
      if (side === 'outer' && !isFirstInGroup) {
        const interConnectionGroup = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID,
          interConnectionGroupID,
          side,
          interConnections: this.allInterConnections,
        })
        newInterConnection = new InterConnection({
          groupID: newGroupID,
          innerTypeID: this.typeData.id,
          outerTypeID: typeID,
        })
        this.addedInterConnections.push(newInterConnection)
        interConnectionGroup.forEach((interConnection) => {
          this.addedInterConnections.push(new InterConnection({
            ...interConnection,
            id: '',
            groupID: newGroupID,
          }))
        })

        // Reset group ID from partly used group
        interConnectionGroupID = null
      }

      // Update group ids for group being added to inner group
      if (rowInterConnectionGroupID) {
        const interConnections = this.allInterConnections.filter(
          (x) => x.groupID === rowInterConnectionGroupID,
        )
        this.onRenameGroupIDForInterConnections({
          interConnections,
          newGroupID: interConnectionGroupID || newGroupID,
        })
      }

      // Check to specify split status
      this.checkToAutoSetSplitStatus({
        side,
        typeID,
        interConnection: newInterConnection,
      })
    },
    onRenameGroupIDForInterConnections({
      interConnections,
      newGroupID,
    }) {
      interConnections.forEach((interConnection) => {
        // Remove old inter connection
        this.removedInterConnectionIDs.push(interConnection.id)

        // Add new inter connections
        this.addedInterConnections.push(new InterConnection({
          ...interConnection,
          id: '',
          groupID: newGroupID,
        }))
      })
    },
    onSplitStatusUpdate({ interConnectionID, status }) {
      const interConnection = this.allInterConnections.find((x) => x.id === interConnectionID)
      interConnection.splitStatus = status
    },
    onEffectStatusUpdate({ interConnectionID, status }) {
      const interConnection = this.allInterConnections.find((x) => x.id === interConnectionID)
      interConnection.effectDirection = status
    },
    onTypeReplaced({
      side,
      rowInterConnectionGroupID,
    }) {
      const existingConnection = this.getExistingConnection({
        side,
        rowInterConnectionGroupID,
      })

      if (!existingConnection) {
        return
      }

      this.removedInterConnectionIDs.push(existingConnection.id)
      let interConnections = []

      if (side === 'inner') {
        interConnections = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID: existingConnection.innerTypeID,
          interConnectionGroupID: existingConnection.groupID,
          side: 'inner',
          interConnections: this.allInterConnections,
        }).filter((x) => x.id !== existingConnection.id)
      }

      if (side === 'outer') {
        interConnections = InterConnection.getGroupInterConnectionsFromTypeID({
          typeID: existingConnection.outerTypeID,
          interConnectionGroupID: existingConnection.groupID,
          side: 'outer',
          interConnections: this.allInterConnections,
        }).filter((x) => x.id !== existingConnection.id)
      }

      if (interConnections.length) {
        const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
        this.onRenameGroupIDForInterConnections({
          interConnections,
          newGroupID,
        })
      }
    },
    onNoTypeSelected({ interConnectionID, removedTypeID }) {
      if (!interConnectionID) {
        return
      }
      const interConnection = this.allInterConnections.find((x) => x.id === interConnectionID)

      // Give second group a different grouop ID if two groups are detected
      const innerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID: removedTypeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'inner',
        interConnections: this.allInterConnections,
      }).filter((x) => x.id !== interConnectionID)
      const outerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID: removedTypeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'outer',
        interConnections: this.allInterConnections,
      }).filter((x) => x.id !== interConnectionID)

      if (innerInterConnections.length && outerInterConnections.length) {
        const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
        this.onRenameGroupIDForInterConnections({
          interConnections: outerInterConnections,
          newGroupID,
        })
      }

      // Remove entry for type existing on its own
      delete this.typesExistsOnTheirOwnAnswers[removedTypeID]

      // Remove entry for duplicating group
      const duplicateIndex = this.existingInterConnectionGroupsToCopy.findIndex(
        (x) => x.originalGroupID === interConnection.groupID,
      )
      if (duplicateIndex > -1) {
        this.existingInterConnectionGroupsToCopy.splice(duplicateIndex, 1)
      }

      // Remove inter connection
      this.removedInterConnectionIDs.push(interConnectionID)
    },
    // eslint-disable-next-line no-unused-vars
    onQuestionAnswer({ answer, questionID, additionalData }) {
      if (questionID === 'type-exists-on-its-own') {
        this.$set(this.typesExistsOnTheirOwnAnswers, additionalData.questionTypeID, answer)
      }

      if (questionID === 'group-exists-on-its-own') {
        const ownGroupQuestion = this.questions.find((x) => x.id === 'group-exists-on-its-own')
        ownGroupQuestion.unresolvedAnswer = answer
      }

      this.onQuestionClose()
    },
    onQuestionClose() {
      const question = this.questions.find((x) => x.id === this.nextQuestion.id)
      question.showQuestion = false
    },
    onNotificationClose() {
      this.showNotificationModal = false
      this.notificationTexts = []
    },
    onCheckBoxClick() {
      this.$emit('exists-on-its-own-update')
    },
    checkToAutoSetSplitStatus({
      interConnection,
    }) {
      const innerType = this.allScreeningTypes.find((x) => x.id === interConnection.innerTypeID)
      const outerType = this.allScreeningTypes.find((x) => x.id === interConnection.outerTypeID)

      let innerCategoryType = null
      let innerTypeWDGID = null
      let outerCategoryType = null
      let outerTypeWDGID = null

      // Find rest of needed values
      innerCategoryType = this.categoryTypes.find((x) => x.id === innerType.type.typeID)
      if (innerType.type.isOtherType) {
        innerTypeWDGID = innerType.type.otherTypeWDGID
      }
      else {
        innerTypeWDGID = innerCategoryType.wdgID
      }
      outerCategoryType = this.categoryTypes.find((x) => x.id === outerType.type.typeID)
      if (outerType.type.isOtherType) {
        outerTypeWDGID = outerType.type.otherTypeWDGID
      }
      else {
        outerTypeWDGID = outerCategoryType.wdgID
      }

      // Case 1 - Plaster on smooth surfaces
      if (innerCategoryType?.tooSmoothForPlasterToStay && outerCategoryType?.id === 'CT-87') {
        interConnection.splitStatus = 'splitable'
        interConnection.isSplitStatusLocked = true
        interConnection.effectDirection = 'right'
      }

      // Case 2 - When outer type has isLeveling or are tiles
      else if (outerCategoryType?.isLeveling || ['C-6', 'C-7'].includes(outerType.categoryID)) {
        if (innerTypeWDGID === 'WDG-2' || innerCategoryType?.tooSmoothForPlasterToStay) {
          interConnection.splitStatus = 'splitable'
          interConnection.effectDirection = 'both'
        }
        else {
          interConnection.splitStatus = 'stuck'
        }
        interConnection.isSplitStatusLocked = true
      }

      // Case 3 - Inner type is WDG-2 and outer type is wallpaper
      else if (innerTypeWDGID === 'WDG-2' && outerCategoryType?.id === 'CT-88') {
        interConnection.splitStatus = 'splitable'
        interConnection.isSplitStatusLocked = true
        interConnection.effectDirection = 'right'
      }

      // Case 4 - When inner or outer type has WDG-2
      else if (innerTypeWDGID === 'WDG-2' || outerTypeWDGID === 'WDG-2') {
        interConnection.splitStatus = 'splitable'
        interConnection.isSplitStatusLocked = true
        interConnection.isCleanableConnection = true
      }
    },
    checkOnUnitsChanged(newUnitIDs) {
      if (!this.interConnectionGroups.length) {
        EventBus.$emit('inter-connections-units-check-status', [])
      }

      const tempMainType = new Type({
        ...JSON.parse(JSON.stringify(this.typeData)),
        unitIDs: newUnitIDs,
      })
      const interConnections = this.allInterConnections.filter(
        (x) => x.outerTypeID === tempMainType.id || x.innerTypeID === tempMainType.id,
      )

      const foundTypes = []
      interConnections.forEach((interConnection) => {
        let innerType = null
        let outerType = null

        if (interConnection.outerTypeID === tempMainType.id) {
          innerType = this.allScreeningTypes.find((x) => x.id === interConnection.innerTypeID)
        }
        else {
          outerType = this.allScreeningTypes.find((x) => x.id === interConnection.outerTypeID)
        }

        let matchFound = false
        if (innerType) {
          matchFound = InterConnection.checkInterConnectionUnits(innerType, tempMainType)
        }
        else {
          matchFound = InterConnection.checkInterConnectionUnits(tempMainType, outerType)
        }

        if (!matchFound) {
          foundTypes.push(innerType || outerType)
        }
      })

      EventBus.$emit('inter-connections-units-check-status', foundTypes)
    },
  },
  components: {
    InterConnectionRow,
    SectionHeadline,
    Checkbox,
    Dialog,
  },
  created() {
    EventBus.$on('split-status-update', this.onSplitStatusUpdate)
    EventBus.$on('effect-status-update', this.onEffectStatusUpdate)
    EventBus.$on('check-inter-connections-on-units-change', this.checkOnUnitsChanged)
  },
  destroyed() {
    EventBus.$off('split-status-update', this.onSplitStatusUpdate)
    EventBus.$off('effect-status-update', this.onEffectStatusUpdate)
    EventBus.$off('check-inter-connections-on-units-change', this.checkOnUnitsChanged)
  },
}
</script>

<style lang="stylus" scoped>
  .InterConnectionsSelect
    padding-top 5px
    padding-bottom 10px

  .ExistsOnItOwnWrap
    padding-left 10px
</style>
