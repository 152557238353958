<template>
  <div class="RadioList">
    <div
      class="Wrap"
      v-for="option in options"
      :key="option.name" >
      <RadioButton
        :text="option.text"
        :groupName="groupName"
        :value="option.name"
        :selectedValue="selectedName"
        @radio-button-click="onRadioButtonClick" />
    </div>
  </div>
</template>

<script>
import { RadioButton } from '@/components/FormElements'

export default {
  name: 'RadioList',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedName: {
      type: String,
      required: false,
      default: '',
    },
    groupName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onRadioButtonClick(value) {
      this.$emit('radio-button-click', value)
    },
  },
  components: {
    RadioButton,
  },
}
</script>

<style lang="stylus" scoped>
  .RadioList
    display block

  .Wrap
    margin-bottom 5px
    display flex
</style>
