<template>
  <div class="SubQuestionsSelect">
    <div
      v-show="question.showQuestion"
      :key="question.id"
      class="SubQuestionWrap"
      v-for="(question, index) in followUpQuestionsMutated">
      <!-- Title -->
      <span
        v-if="index === 0"
        class="SectionTitle">{{ mixWB('FOLLOW_UP_QUESTIONS') }}</span>

      <component
        :is="getComponentToUse(question)"
        :question="question"
        :resetSubQuestionsCounter="resetSubQuestionsCounter"
        :currentCategory="currentCategory"
        @question-updated="onQuestionUpdate"
        @question-finished="onQuestionFinished" />
    </div>

  </div>
</template>

<script>
import SubQuestionType1 from '@/components/ScreeningTypeSections/SubQuestionType1.vue'
import SubQuestionType2 from '@/components/ScreeningTypeSections/SubQuestionType2.vue'
import EventBus from '@/EventBus'

export default {
  name: 'SubQuestionsSelect',
  props: {
    preTitle: {
      type: String,
      required: false,
      default: '',
    },
    followUpQuestions: {
      type: Array,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    resetSubQuestionsCounter: {
      type: Number,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answers: {},
    }
  },
  computed: {
    followUpQuestionsMutated() {
      return this.followUpQuestions.map((question, index) => {
        // Decide to show question
        question.showQuestion = true
        if (index > 0 && this.questionsAnswered < index) {
          question.showQuestion = false
        }

        return question
      })
    },
    questionsAnswered() {
      return Object.keys(this.answers).length
    },
    activeQuestionNo() {
      return this.answers.length
    },
  },
  watch: {
    resetSubQuestionsCounter() {
      this.onResetSubQuestions()
    },
  },
  methods: {
    getComponentToUse(question) {
      if (question.type === 'types-and-questions') {
        return SubQuestionType1
      }
      if (question.type === 'questions-only') {
        return SubQuestionType2
      }
      return false
    },
    onTypeLoad({ subQuestionAnswers }) {
      this.$set(this, 'answers', subQuestionAnswers)

      requestAnimationFrame(() => {
        EventBus.$emit('sub-question-load', this.answers)
      })
    },
    onQuestionUpdate() {
      this.$emit('sub-questions-updated')
    },
    onQuestionFinished({ questionID, questionData }) {
      this.$set(this.answers, questionID, questionData)

      if (this.questionsAnswered !== this.followUpQuestionsMutated.length) {
        return
      }

      this.$emit('sub-questions-finished', this.answers)
    },
    onResetSubQuestions() {
      this.$set(this, 'answers', {})
    },
  },
  created() {
    EventBus.$on('load-type-edit', this.onTypeLoad)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .SubQuestionWrap
    padding 15px 20px 10px
    margin 0px -20px 0px
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    span
      display block

  .SectionTitle
    text-transform uppercase
    margin-bottom 10px
    font-size 1.125rem
</style>
