var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"InterConnectionSelectModal"},[_c('div',{staticClass:"TitleWrap"},[_c('span',{staticClass:"Title"},[_vm._v(_vm._s(_vm.mixWB('SELECT_TYPE')))])]),_c('div',{staticClass:"ButtonWrap"},[_c('Button',{attrs:{"text":_vm.mixWB('NONE'),"color":"grey","outlined":""},on:{"button-click":_vm.onNoTypeClick}}),_c('Select',{attrs:{"name":"categories","optionGroups":_vm.categoryOptionGroups,"selectedID":_vm.selectedCategory.id},on:{"change":_vm.onSelectChange}})],1),_c('div',{staticClass:"ListWrap"},[_c('CategoryTypeList',{attrs:{"categoryID":_vm.selectedCategory.id,"overlayData":{
        showOuterSelect: _vm.showOuterSelect,
        showInnerSelect: _vm.showInnerSelect,
        typeData: _vm.typeData,
        interConnectionGroupID: _vm.interConnectionGroupID,
        allInterConnections: _vm.allInterConnections,
        allScreeningTypes: _vm.allScreeningTypes,
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }