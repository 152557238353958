<template>
  <div class="UnderlineSelect">
    <!-- Title -->
    <span class="Title">{{ mixWB('UNDERLINING') }}</span>

    <!-- Main option select -->
    <SectionHeadline :text="`${ mixWB('IS_THERE_X', [mixWB('UNDERLINING')]) }`" />
    <div class="OptionList">
      <div
        v-show="activeStepNo >= 0"
        v-for="option in questionSchema.options"
        :key="option.id"
        class="Option"
        :class="{ 'IsSelected': option.id === savedData.mainOptionID }"
        @click="onMainOptionClick(option.id)">
        <span>{{ mixWB(option.wordbook) }}</span>
      </div>
    </div>

    <!-- Questions -->
    <QuestionsSelect
      v-show="activeStepNo >= 1 && mainOptionQuestions.length"
      :questions="mainOptionQuestions"
      :resetQuestionsCounter="resetQuestionsCounter"
      subQuestionId="underlining"
      @questions-updated="onQuestionsUpdated"
      @questions-all-answered="onQuestionsAllAnswered" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import { union } from 'lodash-es'
import EventBus from '@/EventBus'
import { findTags } from '@/views/helpers'

export default {
  name: 'UnderlineSelect',
  props: {
    resetUnderliningCounter: {
      type: Number,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      version: '1.0', // X.Y - Bump X when doing breaking changes. Bump Y when doing non-breaking changes.
      activeStepNo: 0,
      roadMap: [
        'main-options', // 0
        'questions', // 1
      ],
      resetQuestionsCounter: 0,
      savedData: {
        version: null,
        mainOptionID: null,
        answers: {},
        underliningTypeIDs: [],
        samples: {
          sampleIDs: [],
          items: [],
        },
        tags: {},
      },
      questionSchema: {
        options: [
          {
            dkName: 'Ingen',
            id: 'O-1',
            wordbook: 'NONE',
          },
          {
            dkName: 'Tjære',
            underliningTypeIDs: ['UNLI-2'],
            id: 'O-2',
            sampleIDs: ['ST-3'],
            questions: [
              {
                id: 'Q-1',
                position: 0,
                sampleIDs: ['ST-4'],
                tagIDs: ['TAG-1978'],
                wordbook: 'FROM_BEFORE_1978',
              },
            ],
            wordbook: 'TAR',
          },
          {
            dkName: 'PU-skum',
            underliningTypeIDs: ['UNLI-3'],
            id: 'O-3',
            wordbook: 'PU_FOAM',
          },
          {
            dkName: 'Mørtel',
            underliningTypeIDs: ['UNLI-4'],
            id: 'O-4',
            wordbook: 'MORTAR',
            questions: [
              {
                id: 'Q-1',
                position: 0,
                sampleIDs: ['ST-1'],
                tagIDs: ['TAG-1988'],
                wordbook: 'FROM_BEFORE_1988',
              },
            ],
          },
        ],
      },
    }
  },
  computed: {
    selectedMainOption() {
      return this.questionSchema.options.find((x) => x.id === this.savedData.mainOptionID)
    },
    mainOptionQuestions() {
      if (!this.selectedMainOption) {
        return []
      }

      if (this.selectedMainOption.id === 'O-1') {
        return []
      }

      if (!this.selectedMainOption.questions) {
        return []
      }

      return this.selectedMainOption.questions
    },
  },
  watch: {
    resetUnderliningCounter() {
      this.onResetUnderlining()
    },
  },
  methods: {
    onMainOptionClick(optionID) {
      if (this.savedData.mainOptionID === optionID) {
        return
      }

      this.activeStepNo = 0
      this.savedData.mainOptionID = optionID

      // Reset
      this.savedData.answers = {}
      this.savedData.underliningTypeIDs = []
      this.savedData.samples.sampleIDs = []
      this.savedData.samples.items = []
      this.resetQuestionsCounter += 1

      this.onNextStep()
    },
    onQuestionsUpdated() {
      this.$emit('underlining-updated')
    },
    onQuestionsAllAnswered(answers) {
      this.$set(this.savedData, 'answers', answers)
      this.onNextStep()
    },
    onNextStep() {
      this.$emit('underlining-updated')

      if (this.activeStepNo === 0) {
        // Questions
        if (this.mainOptionQuestions.length) {
          this.activeStepNo += 2
          return
        }
      }

      if (this.activeStepNo === 1) {
        // Do nothing
      }

      this.saveUnderliningAndSamples()

      // Set version if missing
      if (!this.savedData.version) {
        this.savedData.version = this.version
      }

      this.$emit('underlining-finished', this.savedData)
    },
    saveUnderliningAndSamples() {
      this.savedData.underliningTypeIDs = []
      this.savedData.samples.sampleIDs = []
      this.savedData.samples.items = []

      const tags = findTags({
        mainQuestions: this.mainOptionQuestions,
        mainQuestionAnswers: this.savedData.answers,
      })

      this.savedData.tags = tags

      if (this.selectedMainOption.id === 'O-1') {
        return
      }

      // Set underlining types from selected option
      if (this.selectedMainOption.underliningTypeIDs.length) {
        this.savedData.underliningTypeIDs = this.selectedMainOption.underliningTypeIDs
      }

      // Set sample from selected option
      if (this.selectedMainOption.sampleIDs && this.selectedMainOption.sampleIDs.length) {
        this.savedData.samples.sampleIDs = this.savedData.samples.sampleIDs.concat(
          this.selectedMainOption.sampleIDs,
        )
        this.savedData.samples.items.push({
          isMain: true,
          optionID: this.selectedMainOption.id,
          sampleIDs: this.selectedMainOption.sampleIDs,
        })
      }

      Object.keys(this.savedData.answers).forEach((questionID) => {
        if (this.savedData.answers[questionID] === 0) {
          return
        }

        const question = this.mainOptionQuestions.find((x) => x.id === questionID)

        // Remove answer if question is no longer available
        if (!question) {
          delete this.savedData.answers[questionID]
          return
        }

        if (question.sampleIDs && question.sampleIDs.length) {
          this.savedData.samples.items.push({
            isQuestion: true,
            questionID,
            answer: this.savedData.answers[questionID],
            sampleIDs: question.sampleIDs,
          })

          this.savedData.samples.sampleIDs = union(
            this.savedData.samples.sampleIDs,
            question.sampleIDs,
          )
        }
      })
    },
    onTypeUnderliningLoad({ underlining, groupName }) {
      if (this.groupName !== groupName) {
        return
      }

      this.$set(this, 'savedData', underlining)
      this.activeStepNo = this.roadMap.length - 1
      EventBus.$emit('load-sub-question', { id: 'underlining', ...this.savedData })
    },
    onResetUnderlining() {
      this.savedData = {
        version: null,
        mainOptionID: null,
        answers: {},
        underliningTypeIDs: [],
        samples: {
          sampleIDs: [],
          items: [],
        },
      }
    },
  },
  components: {
    SectionHeadline,
    QuestionsSelect,
  },
  created() {
    EventBus.$on('load-type-underlining', this.onTypeUnderliningLoad)
  },
  destroyed() {
    EventBus.$off('load-type-underlining', this.onTypeUnderliningLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .UnderlineSelect
    margin 0 -20px 0px
    padding 15px 20px 5px
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    span
      display block

  .Title
    text-transform uppercase
    margin-bottom 10px
    font-size 1.125rem

  .OptionList
    display flex
    flex-wrap wrap
    margin 0px -5px 15px

  .Option
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
