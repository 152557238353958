import { union } from 'lodash-es'

export const findTags = ({
  mainQuestions = [],
  mainQuestionAnswers = {},
  subQuestions = [],
  subQuestionAnswers = {},
  autoTypes = [],
}) => {
  const tags = {}
  // Look in main questions
  let lastAnswer = null
  mainQuestions.forEach((question) => {
    if (!mainQuestionAnswers.hasOwnProperty(question.id)) {
      return
    }

    if (!question.tagIDs?.length) {
      return
    }

    const answer = mainQuestionAnswers[question.id]

    // Omit questions that has auto no answer
    if (!question.alwaysShow && lastAnswer === 0) {
      return
    }

    question.tagIDs.forEach((tagID) => {
      tags[tagID] = answer
    })

    lastAnswer = answer
  })

  // Look in sub questions
  Object.keys(subQuestionAnswers).forEach((subQuestionID) => {
    const answers = subQuestionAnswers[subQuestionID]?.answers || {}
    const subQuestion = subQuestions.find((x) => x.id === subQuestionID)

    if (subQuestion.type === 'questions-only') {
      // let lastAnswer = null
      subQuestion.questions.forEach((question) => {
        if (!answers.hasOwnProperty(question.id)) {
          return
        }
        if (!question.tagIDs?.length) {
          return
        }

        const answer = answers[question.id]

        // Omit questions that has auto no answer
        if (!question.alwaysShow && lastAnswer === 0) {
          return
        }

        question.tagIDs.forEach((tagID) => {
          tags[tagID] = answer
        })

        lastAnswer = answer
      })
    }
  })

  // Look in auto types
  autoTypes.forEach((autoType) => {
    if (autoType.answer !== 'yes') {
      return
    }

    if (autoType.id === 'CT-87') {
      tags['TAG-3'] = 1
    }
    if (autoType.id === 'CT-88') {
      tags['TAG-4'] = 1
    }
  })

  return tags
}

export const findSamples = ({
  mainQuestionAnswers,
  selectedCategoryType,
  subQuestionAnswers,
  categoryTypeSubQuestions,
  isOtherType,
  typeQuestions,
}) => {
  const samples = {
    items: [],
    sampleIDs: [],
  }
  const assessments = {
    items: [],
    assessmentIDs: [],
  }

  // Samples - Look in main type
  if (
    selectedCategoryType
    && selectedCategoryType.sampleIDs
    && selectedCategoryType.sampleIDs.length
  ) {
    samples.items.push({
      isMain: true,
      materialID: selectedCategoryType.id,
      sampleIDs: selectedCategoryType.sampleIDs,
    })

    samples.sampleIDs = union(samples.sampleIDs, selectedCategoryType.sampleIDs)
  }

  // Assessments - Look in main type
  if (
    selectedCategoryType
    && selectedCategoryType.assessmentIDs
    && selectedCategoryType.assessmentIDs.length
  ) {
    assessments.items.push({
      isMain: true,
      materialID: selectedCategoryType.id,
      assessmentIDs: selectedCategoryType.assessmentIDs,
    })

    assessments.assessmentIDs = union(
      assessments.assessmentIDs,
      selectedCategoryType.assessmentIDs,
    )
  }

  // Look in main questions
  Object.keys(mainQuestionAnswers).forEach((questionID) => {
    if (mainQuestionAnswers[questionID] === 0) {
      return
    }

    const question = typeQuestions.find((x) => x.id === questionID)

    // Samples
    if (question.sampleIDs && question.sampleIDs.length) {
      samples.items.push({
        isMain: true,
        isOtherType,
        questionID,
        answer: mainQuestionAnswers[questionID],
        sampleIDs: question.sampleIDs,
      })

      samples.sampleIDs = union(samples.sampleIDs, question.sampleIDs)
    }

    // Assessments
    if (question.assessmentIDs && question.assessmentIDs.length) {
      assessments.items.push({
        isMain: true,
        isOtherType,
        questionID,
        answer: mainQuestionAnswers[questionID],
        assessmentIDs: question.assessmentIDs,
      })

      assessments.assessmentIDs = union(
        assessments.assessmentIDs,
        question.assessmentIDs,
      )
    }
  })

  // Look at follow up questions
  Object.keys(subQuestionAnswers).forEach((subQuestionID) => {
    const subQuestionData = subQuestionAnswers[subQuestionID]
    const subQuestion = categoryTypeSubQuestions.find((x) => x.id === subQuestionID)

    if (subQuestion.type === 'types-and-questions') {
      if (subQuestionData.materialID === '') {
        return
      }
      const selectedOption = subQuestion.options.find(
        (x) => x.materialID === subQuestionData.materialID,
      )

      // Check for selected material
      // - Samples
      if (selectedOption.sampleIDs && selectedOption.sampleIDs.length) {
        samples.items.push({
          subQuestionID,
          type: subQuestion.type,
          materialID: selectedOption.materialID,
          sampleIDs: selectedOption.sampleIDs,
        })

        samples.sampleIDs = union(samples.sampleIDs, selectedOption.sampleIDs)
      }
      // - Assessments
      if (selectedOption.assessmentIDs && selectedOption.assessmentIDs.length) {
        assessments.items.push({
          subQuestionID,
          type: subQuestion.type,
          materialID: selectedOption.materialID,
          assessmentIDs: selectedOption.assessmentIDs,
        })

        assessments.assessmentIDs = union(
          assessments.assessmentIDs,
          selectedOption.assessmentIDs,
        )
      }

      // Check for each question answered
      Object.keys(subQuestionData.answers).forEach((questionID) => {
        if (subQuestionData.answers[questionID] === 0) {
          return
        }

        const question = selectedOption.questions.find((x) => x.id === questionID)

        // - Samples
        if (question.sampleIDs && question.sampleIDs.length) {
          samples.items.push({
            subQuestionID,
            type: subQuestion.type,
            materialID: selectedOption.materialID,
            questionID,
            answer: subQuestionData.answers[questionID],
            sampleIDs: question.sampleIDs,
          })

          samples.sampleIDs = union(samples.sampleIDs, question.sampleIDs)
        }
        // - Assessments
        if (question.assessmentIDs && question.assessmentIDs.length) {
          assessments.items.push({
            subQuestionID,
            type: subQuestion.type,
            materialID: selectedOption.materialID,
            questionID,
            answer: subQuestionData.answers[questionID],
            assessmentIDs: question.assessmentIDs,
          })

          assessments.assessmentIDs = union(
            assessments.assessmentIDs,
            question.assessmentIDs,
          )
        }
      })
    }

    if (subQuestion.type === 'questions-only') {
      // Check for each question answered
      Object.keys(subQuestionData.answers).forEach((questionID) => {
        if (subQuestionData.answers[questionID] === 0) {
          return
        }

        const question = subQuestion.questions.find((x) => x.id === questionID)

        // - Samples
        if (question.sampleIDs && question.sampleIDs.length) {
          samples.items.push({
            subQuestionID,
            type: subQuestion.type,
            questionID,
            answer: subQuestionData.answers[questionID],
            sampleIDs: question.sampleIDs,
          })

          samples.sampleIDs = union(samples.sampleIDs, question.sampleIDs)
        }

        // - Assessments
        if (question.assessmentIDs && question.assessmentIDs.length) {
          assessments.items.push({
            subQuestionID,
            type: subQuestion.type,
            questionID,
            answer: subQuestionData.answers[questionID],
            assessmentIDs: question.assessmentIDs,
          })

          assessments.assessmentIDs = union(
            assessments.assessmentIDs,
            question.assessmentIDs,
          )
        }
      })
    }
  })

  // Special rule for CT-64 (Soft joints)
  if (samples.sampleIDs.indexOf('ST-4') > -1) {
    const index = samples.sampleIDs.indexOf('ST-5')
    if (index > -1) {
      samples.sampleIDs.splice(index, 1)
    }
  }

  return {
    samples,
    assessments,
  }
}
