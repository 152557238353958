<template>
  <div class="ResaleSelect">
    <SectionHeadline :text="title" />
    <RadioList
      :options="mutatedOptions"
      :selected-name="selectedName"
      :group-name="groupName"
      @radio-button-click="onRadioButtonClick" />

    <ErrorText
      v-if="showMissingError"
      :text="mixWB('SELECT_AN_OPTION')"
      withBackground />
  </div>
</template>

<script>
import { SectionHeadline } from '@/components/FormElements'
import { RadioList } from '@/components/Forms'
import EventBus from '@/EventBus'
import ErrorText from '@/components/FormElements/ErrorText.vue'

export default {
  name: 'ResaleSelect',
  props: {
    typeData: {
      type: Object,
      required: true,
    },
    mainTypeSampleIDs: {
      type: Array,
      required: true,
    },
    resetResaleCounter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          name: 'no',
          text: this.mixWB('NO'),
        },
        {
          name: 'yes',
          text: this.mixWB('YES'),
        },
        {
          name: 'potential',
          text: this.mixWB('POTENTIAL'),
        },
      ],
      selectedName: '',
      groupName: 'resale',
      showMissingError: false,
    }
  },
  computed: {
    title() {
      return `${ this.mixWB('RESALE') } - ${ this.typeData.getTitle() }`
    },
    mutatedOptions() {
      return this.options.filter(
        (x) => x.name !== 'yes' || (x.name === 'yes' && !this.mainTypeSampleIDs.includes('ST-1')),
      )
    },
  },
  watch: {
    resetResaleCounter() {
      this.onResetResale()
    },
  },
  methods: {
    onTypeLoad({ resale }) {
      this.selectedName = resale
    },
    onMissingResale() {
      this.showMissingError = true
    },
    onRadioButtonClick(value) {
      this.showMissingError = false
      this.selectedName = value

      this.$emit('resale-update', value)
    },
    onResetResale() {
      this.selectedName = ''
    },
  },
  components: {
    SectionHeadline,
    RadioList,
    ErrorText,
  },
  created() {
    EventBus.$on('load-type-edit', this.onTypeLoad)
    EventBus.$on('missing-resale', this.onMissingResale)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onTypeLoad)
    EventBus.$off('missing-resale', this.onMissingResale)
  },
}
</script>

<style lang="stylus" scoped>
  .ResaleSelect
    padding-top 15px
    padding-bottom 10px
</style>
