// Type select and questions for that type (if any)

<template>
  <div class="SubQuestionType1">

    <!-- Type -->
    <TypeSelect
      v-show="activeStepNo >= 0"
      :isActive="activeStepNo === 0"
      :title="question.wordbook"
      :types="typeOptions"
      :selectedID="questionData.materialID"
      @type-click="onTypeClick" />

    <!-- Questions -->
    <QuestionsSelect
      v-show="activeStepNo >= 1 && optionQuestions.length"
      :isActive="activeStepNo === 1"
      :questions="optionQuestions"
      :subQuestionId="question.id"
      :resetQuestionsCounter="resetQuestionsCounter"
      @questions-updated="onQuestionsUpdated"
      @questions-all-answered="onQuestionsAllAnswered" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TypeSelect from '@/components/ScreeningTypeSections/TypeSelect.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import EventBus from '@/EventBus'

export default {
  name: 'SubQuestionType1',
  props: {
    question: {
      type: Object,
      required: true,
    },
    resetSubQuestionsCounter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      questionData: {
        materialID: null,
        answers: {},
      },
      activeStepNo: 0,
      roadMap: [
        'type',
        'questions',
      ],
      // Resets
      resetQuestionsCounter: 0,
    }
  },
  computed: {
    ...mapGetters([
      'materials',
    ]),
    activeStep() {
      return this.roadMap[this.activeStepNo]
    },
    typeOptions() {
      return this.question.options.map((option) => {
        // Set type wordbook
        if (option.materialID) {
          const material = this.materials.find((x) => x.id === option.materialID)
          option.wordbook = this.mixWB(material.wordbook)
        }
        return option
      })
    },
    currentOption() {
      return this.typeOptions.find((x) => x.materialID === this.questionData.materialID)
    },
    optionQuestions() {
      if (!this.currentOption) {
        return []
      }

      return this.currentOption.questions || []
    },
  },
  watch: {
    resetSubQuestionsCounter() {
      this.questionData.materialID = null
      this.resetQuestions()
    },
  },
  methods: {
    onTypeClick(type) {
      if (this.questionData.materialID === type.materialID) {
        return
      }

      this.activeStepNo = 0
      this.questionData.materialID = type.materialID

      this.resetQuestions()

      this.onNext()
    },
    onQuestionTypeLoad(answers) {
      Object.keys(answers).forEach((key) => {
        if (this.question.id !== key) {
          return
        }

        this.activeStepNo = this.roadMap.length - 1

        this.questionData.materialID = answers[key].materialID
        this.$set(this.questionData, 'answers', answers[key].answers)
      })

      EventBus.$emit('load-sub-question', { id: this.question.id, ...this.questionData })
    },
    onQuestionsUpdated() {
      this.$emit('question-updated')
    },
    onQuestionsAllAnswered(answers) {
      this.$set(this.questionData, 'answers', answers)
      this.onNext()
    },
    resetQuestions() {
      this.questionData.answers = {}
      this.resetQuestionsCounter += 1
    },
    onNext() {
      this.$emit('question-updated')

      if (this.activeStep === 'type') {
        if (this.optionQuestions.length) {
          this.activeStepNo += 1
          return
        }
      }

      this.$emit('question-finished', {
        questionID: this.question.id,
        questionData: this.questionData,
      })
    },
  },
  components: {
    TypeSelect,
    QuestionsSelect,
  },
  created() {
    EventBus.$on('sub-question-load', this.onQuestionTypeLoad)
  },
  destroyed() {
    EventBus.$off('sub-question-load', this.onQuestionTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .SubQuestionType1
    display block
</style>
