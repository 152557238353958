var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"InterConnectionRow",staticClass:"InterConnectionRow"},[_c('div',{staticClass:"Row js-scroll-container"},[_vm._l((_vm.mutatedTypeItems),function(item){return [(item === 'empty-inner')?_c('div',{key:item,staticClass:"Content",on:{"click":function($event){return _vm.onBoxClick('inner')}}},[_c('InterConnectionSelectBox',{attrs:{"type":false,"interConnection":false,"side":"inner"},on:{"updated":function($event){_vm.errorText = ''}}})],1):(item === 'empty-outer')?_c('div',{key:item,staticClass:"Content",on:{"click":function($event){return _vm.onBoxClick('outer')}}},[_c('InterConnectionSelectBox',{attrs:{"type":false,"interConnection":false,"side":"outer"},on:{"updated":function($event){_vm.errorText = ''}}})],1):(item && item.type)?_c('div',{key:item.type.id,ref:item.type.id,refInFor:true,staticClass:"Content",on:{"click":function($event){return _vm.onBoxClick(item.isOutsideScope || item.isAutoType ? '' : item.side)}}},[_c('InterConnectionSelectBox',{attrs:{"type":item.type,"side":item.side,"interConnection":item.innerInterConnection,"isMainType":item.isMainType,"isOutsideScope":item.isOutsideScope},on:{"updated":function($event){_vm.errorText = ''}}})],1):_vm._e()]})],2),_c('div',{staticClass:"ErrorWrap"},[(_vm.errorText)?_c('ErrorText',{attrs:{"text":_vm.errorText,"withBackground":""}}):_vm._e()],1),_c('Dialog',{attrs:{"isShowing":_vm.showInterConnectionSelectModal,"useComponent":_vm.InterConnectionSelectModal,"componentProps":{
      showOuterSelect: _vm.showOuterSelect,
      showInnerSelect: _vm.showInnerSelect,
      typeData: _vm.typeData,
      interConnectionGroupID: _vm.interConnectionGroupID,
      allInterConnections: _vm.allInterConnections,
      allScreeningTypes: _vm.allScreeningTypes,
    }},on:{"no-type-selected":_vm.onNoTypeSelected,"close":function($event){_vm.showInterConnectionSelectModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }