<template>
  <div
    ref="InterConnectionRow"
    class="InterConnectionRow">
    <div class="Row js-scroll-container">
      <template v-for="item in mutatedTypeItems">
        <!-- Inner empty -->
        <div
          v-if="item === 'empty-inner'"
          :key="item"
          class="Content"
          @click="onBoxClick('inner')">
          <InterConnectionSelectBox
            :type="false"
            :interConnection="false"
            side="inner"
            @updated="errorText = ''" />
        </div>

        <!-- Outer empty -->
        <div
          v-else-if="item === 'empty-outer'"
          :key="item"
          class="Content"
          @click="onBoxClick('outer')">
          <InterConnectionSelectBox
            :type="false"
            :interConnection="false"
            side="outer"
            @updated="errorText = ''" />
        </div>

        <!-- Created types -->
        <div
          v-else-if="item && item.type"
          :key="item.type.id"
          :ref="item.type.id"
          class="Content"
          @click="onBoxClick(item.isOutsideScope || item.isAutoType ? '' : item.side)">
          <InterConnectionSelectBox
            :type="item.type"
            :side="item.side"
            :interConnection="item.innerInterConnection"
            :isMainType="item.isMainType"
            :isOutsideScope="item.isOutsideScope"
            @updated="errorText = ''" />
        </div>
      </template>
    </div>

    <div class="ErrorWrap">
      <ErrorText
        v-if="errorText"
        :text="errorText"
        withBackground />
    </div>

    <Dialog
      :isShowing="showInterConnectionSelectModal"
      :useComponent="InterConnectionSelectModal"
      :componentProps="{
        showOuterSelect,
        showInnerSelect,
        typeData,
        interConnectionGroupID,
        allInterConnections,
        allScreeningTypes,
      }"
      @no-type-selected="onNoTypeSelected"
      @close="showInterConnectionSelectModal = false" />
  </div>
</template>

<script>
import InterConnectionSelectBox from '@/components/ScreeningTypeSections/InterConnections/InterConnectionSelectBox.vue'
import InterConnectionSelectModal from '@/components/ScreeningTypeSections/InterConnections/InterConnectionSelectModal.vue'
import ErrorText from '@/components/FormElements/ErrorText.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import EventBus from '@/EventBus'
import { getElementDocumentPos, scrollTo } from '@/globals/javascript/_util/util'
import { getClosestByClass } from '@/globals/javascript/_util/find-elements'
import { mapGetters } from 'vuex'

export default {
  name: 'InterConnectionRow',
  props: {
    interConnectionGroupID: {
      type: String,
      required: false,
      default: '',
    },
    typeItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    typeData: {
      type: Object,
      required: true,
    },
    autoTypes: {
      type: Array,
      required: true,
    },
    allInterConnections: {
      type: Array,
      required: true,
    },
    allScreeningTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      InterConnectionSelectModal,
      showInterConnectionSelectModal: false,
      showOuterSelect: false,
      showInnerSelect: false,
      errorText: '',
    }
  },
  computed: {
    ...mapGetters([
      'categoryTypes',
    ]),
    autoTypeIDs() {
      return this.autoTypes.reduce((prev, autoType) => {
        if (autoType.answer === 'yes') {
          prev.push(autoType.type.id)
        }
        return prev
      }, [])
    },
    innerTypeItem() {
      const mainTypeIndex = this.typeItems.findIndex(
        (x) => x.type.id === this.typeData.id,
      )
      return this.typeItems[mainTypeIndex - 1] || false
    },
    outerTypeItem() {
      const mainTypeIndex = this.typeItems.findIndex(
        (x) => x.type.id === this.typeData.id,
      )
      return this.typeItems[mainTypeIndex + 1] || false
    },
    mutatedTypeItems() {
      const items = []

      if (!this.innerTypeItem) {
        items.push('empty-inner')
      }

      if (!this.interConnectionGroupID) {
        const data = {
          type: this.typeData,
          innerInterConnection: null,
          outerInterConnection: null,
          isMainType: true,
          isOutsideScope: false,
          side: 'middle',
        }
        items.push(data)
      }

      let isMiddleReached = false
      this.typeItems.forEach((typeItem) => {
        const data = {
          ...typeItem,
          isMainType: false,
          isAutoType: false,
          isOutsideScope: true,
          side: isMiddleReached ? 'outer' : 'inner',
        }

        if (typeItem.type.id === this.typeData.id) {
          data.isMainType = true
          data.isOutsideScope = false
          data.side = 'middle'
          isMiddleReached = true
        }

        if (this.autoTypeIDs.includes(typeItem.type.id)) {
          data.isAutoType = true
          data.isOutsideScope = false
        }

        // Set data props
        if (!data.isMainType && !data.isAutoType) {
          if (this.innerTypeItem?.type?.id === typeItem.type.id) {
            data.isOutsideScope = false
            data.innerInterConnection = null
          }
          if (this.outerTypeItem?.type?.id === typeItem.type.id) {
            data.isOutsideScope = false
          }
        }
        if (data.isOutsideScope) {
          data.innerInterConnection = null
        }

        items.push(data)
      })

      if (!this.outerTypeItem) {
        items.push('empty-outer')
      }

      return items
    },
  },
  watch: {
    mutatedTypeItems(to, from) {
      if (to.length > from.length) {
        requestAnimationFrame(() => {
          this.setStartScrollPosition()
        })
      }
    },
  },
  methods: {
    onBoxClick(side) {
      if (!['inner', 'outer'].includes(side)) {
        return
      }
      if (side === 'inner') {
        this.showOuterSelect = false
        this.showInnerSelect = true
      }
      if (side === 'outer') {
        this.showOuterSelect = true
        this.showInnerSelect = false
      }
      this.showInterConnectionSelectModal = true
    },
    onNoTypeSelected({ side }) {
      this.errorText = ''
      this.showInterConnectionSelectModal = false

      if (side === 'inner') {
        this.$emit('no-type-selected', {
          removedTypeID: this.innerTypeItem?.outerInterConnection?.innerTypeID,
          interConnectionID: this.innerTypeItem?.outerInterConnection?.id,
        })
      }
      if (side === 'outer') {
        this.$emit('no-type-selected', {
          removedTypeID: this.outerTypeItem?.innerInterConnection?.outerTypeID,
          interConnectionID: this.outerTypeItem?.innerInterConnection?.id,
        })
      }
    },
    onInterConnectionClick(data) {
      if (!this.showInterConnectionSelectModal) {
        return
      }

      this.errorText = ''
      this.showInterConnectionSelectModal = false
      this.$emit('inter-connection-select', {
        ...data,
        rowInterConnectionGroupID: this.interConnectionGroupID,
      })
    },
    onInvalidInterConnection({
      isSplitStatusOK,
      isEffectDirectionOK,
      groupID,
      scrollToHere,
    }) {
      if (this.interConnectionGroupID !== groupID) {
        return
      }

      if (!isSplitStatusOK) {
        this.errorText = this.mixWB('CHOOSE_WHETHER_THE_TYPES_CAN_BE_SEPARATED')
      }
      else if (!isEffectDirectionOK) {
        this.errorText = this.mixWB('CHOOSE_ROUTE_OF_INFECTION')
      }

      if (scrollToHere) {
        scrollTo({
          element: this.$refs.InterConnectionRow,
          offset: -50,
        })
      }
    },
    setStartScrollPosition() {
      if (!this.interConnectionGroupID) {
        return
      }

      const mainType = this.$refs[this.typeData.id][0]
      const mainTypePos = getElementDocumentPos(mainType)
      const parentScrollElement = getClosestByClass('js-scroll-container', mainType)
      const newLeftScrollPos = mainTypePos.left
          - (window.innerWidth / 2) + (mainTypePos.right - mainTypePos.left)

      requestAnimationFrame(() => {
        parentScrollElement.scrollLeft = newLeftScrollPos
      })
    },
  },
  components: {
    InterConnectionSelectBox,
    ErrorText,
    Dialog,
  },
  created() {
    EventBus.$on('inter-connection-select', this.onInterConnectionClick)
    EventBus.$on('invalid-inter-connection', this.onInvalidInterConnection)
  },
  mounted() {
    this.setStartScrollPosition()
  },
  destroyed() {
    EventBus.$off('inter-connection-select', this.onInterConnectionClick)
    EventBus.$off('invalid-inter-connection', this.onInvalidInterConnection)
  },
}
</script>

<style lang="stylus" scoped>
  .InterConnectionRow
    display block
    margin-left -20px
    margin-right -20px
    margin-bottom 10px

  .Row
    display flex
    overflow-x auto
    scroll-snap-type x mandatory
    padding-left 30px
    padding-right 30px

  .Content
    scroll-snap-align center
    width calc(33.33% - 10px)
    min-height 40px
    flex-shrink 0
    margin-left 7.5px
    margin-right 7.5px
    &:first-child
      margin-left 0px
    &:last-child
      margin-right 0px

  .ErrorWrap
    margin-left 30px
    margin-right 30px

</style>
