<template>
  <div class="NoficationModal">
    <div class="IconWrap">
      <WarningIcon />
    </div>

    <div class="TextWrap">
      <span
        v-for="text in texts"
        :key="text"
        v-html="text"/>
    </div>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('OK')"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import WarningIcon from '@/assets/svg/warning.svg?inline'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'NoficationModal',
  props: {
    texts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('close-dialog')
    },
  },
  components: {
    WarningIcon,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .NoficationModal
    display block
    padding-top 15px

  .IconWrap
    box(60px)
    margin 20px auto 25px
    svg
      fill $color_warning

  .TextWrap
    padding 0px 20px 30px
    span
      text-align center
      font-size 1.25rem
      padding-bottom 10px

  .ButtonWrap
    display flex
    justify-content center
    >>> .ButtonWrap
      width 50%
</style>
