<template>
  <div class="UnitSelectGroup">
    <div
      class="Unit"
      v-for="(unit, index) in groupUnits"
      :key="unit.id">
      <UnitSelectGroupUnit
        :unit="unit"
        :isFirstInGroup="index === 0"
        :isSelected="selectedUnitIDs.includes(unit.id)"
        :topLevel="topLevel"
        @unit-click="onUnitClick" />
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import UnitSelectGroupUnit from '@/components/ScreeningTypeSections/UnitSelectGroupUnit.vue'

export default {
  name: 'UnitSelectGroup',
  props: {
    group: {
      type: Object,
      required: true,
    },
    selectedUnitIDs: {
      type: Array,
      required: true,
    },
    topLevel: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
    ]),
    groupUnits() {
      let units = []
      const mainUnit = this.selectedUnits.find((x) => x.id === this.group.id)

      // Add main unit
      units.push(mainUnit)

      // Add floors
      const floors = sortBy(
        this.selectedUnits.filter((x) => mainUnit.floorIDs?.includes(x.id)),
        ['data.numericFloorValue'],
      )
      units = units.concat(floors)

      // Add apartments
      const apartments = sortBy(
        this.selectedUnits.filter((x) => mainUnit.apartmentIDs?.includes(x.id)),
        ['data.numericFloorValue'],
      ).reverse()
      apartments.forEach((apartment) => {
        const floorIndex = units.findIndex((x) => x.id === apartment.data.floorID)
        if (floorIndex > -1) {
          units.splice(floorIndex + 1, 0, apartment)
          return
        }

        units.splice(1, 0, apartment)
      })

      return units
    },
  },
  methods: {
    onUnitClick(unitID) {
      this.$emit('unit-click', unitID)
    },
  },
  components: {
    UnitSelectGroupUnit,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitSelectGroup
    display block
</style>
