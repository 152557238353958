<template>
  <div
    class="QuestionsSelect"
    :class="rootClasses">
    <!-- Title -->
    <span
      v-if="title"
      class="Title">{{ title }}</span>

    <div
      v-for="(question, index) in questionList"
      v-show="question.showQuestion"
      :key="question.text"
      class="QuestionWrap">
      <SectionHeadline :text="getQuestionTitle(question)" />
      <div class="OptionsWrap">
        <div
          :class="{ 'IsSelected': question.answer === 1 }"
          class="Option"
          @click="onOptionClick(1, question, index)">
          <span>{{ mixWB('YES') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': question.answer === 0 }"
          class="Option"
          @click="onOptionClick(0, question, index)">
          <span>{{ mixWB('NO') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': question.answer === 2 }"
          class="Option"
          @click="onOptionClick(2, question, index)">
          <span>{{ mixWB('DONT_KNOW') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SectionHeadline } from '@/components/FormElements'
import EventBus from '@/EventBus'

export default {
  name: 'QuestionsSelect',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    preTitle: {
      type: String,
      required: false,
      default: '',
    },
    questions: {
      type: Array,
      required: true,
    },
    subQuestionId: {
      type: String,
      required: false,
      default: '',
    },
    resetQuestionsCounter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      answers: {},
    }
  },
  computed: {
    rootClasses() {
      return {
        HasTitle: !!this.title,
      }
    },
    questionList() {
      let lastAnswer = null
      return this.questions.map((question, index) => {
        // Add answer
        question.answer = this.answers[question.id] || this.answers[question.id] === 0
          ? this.answers[question.id]
          : null

        // Check to show
        question.showQuestion = true

        if (index === 0) {
          lastAnswer = question.answer
          return question
        }

        if (lastAnswer > 0) {
          lastAnswer = question.answer
          return question
        }

        if (question.alwaysShow && lastAnswer !== null) {
          lastAnswer = question.answer
          return question
        }

        question.showQuestion = false
        lastAnswer = question.answer
        return question
      })
    },
  },
  watch: {
    resetQuestionsCounter() {
      this.onResetQuestions()
    },
  },
  methods: {
    getQuestionTitle(question) {
      if (this.preTitle) {
        return `${ this.preTitle }: ${ this.mixWB(question.wordbook) }`
      }

      return this.mixWB(question.wordbook)
    },
    onLoadType({ questionAnswers }) {
      if (this.subQuestionId) {
        return
      }

      this.$set(this, 'answers', questionAnswers)
    },
    onLoadSubQuestion({ id, answers }) {
      if (id !== this.subQuestionId) {
        return
      }

      this.$set(this, 'answers', answers)
    },
    onOptionClick(answer, question, index) {
      if (question.answer === answer) {
        return
      }

      this.$set(this.answers, question.id, answer)

      // Reset below questions
      let alwaysShowQuestionFound = false
      this.questionList.forEach((item, itemIndex) => {
        if (itemIndex > index) {
          if (alwaysShowQuestionFound) {
            return
          }
          if (item.alwaysShow) {
            alwaysShowQuestionFound = true
            return
          }
          if (answer === 0) {
            this.$set(this.answers, item.id, 0)
          }
          else {
            this.$delete(this.answers, item.id)
          }
        }
      })

      if (Object.keys(this.answers).length !== this.questionList.length) {
        this.$emit('questions-updated')
        return
      }

      // Report when all questions are answered
      this.$emit('questions-all-answered', this.answers)
    },
    onResetQuestions() {
      this.$set(this, 'answers', {})
    },
  },
  components: {
    SectionHeadline,
  },
  created() {
    EventBus.$on('load-type-edit', this.onLoadType)
    EventBus.$on('load-sub-question', this.onLoadSubQuestion)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onLoadType)
    EventBus.$off('load-sub-question', this.onLoadSubQuestion)
  },
}
</script>

<style lang="stylus" scoped>
  .QuestionsSelect
    border-top 1px solid $color_grey_lighter
    margin 0 -20px 0px
    padding 10px 20px 10px
    span
      display block
    &.HasTitle
      padding-top 15px

  .Title
    text-transform uppercase
    margin-bottom 10px
    font-size 1.125rem

  .QuestionWrap
    margin-bottom 10px

  .OptionsWrap
    display flex
    justify-content space-between

  .Option
    display flex
    align-items center
    justify-content center
    width calc(33.33% - 7px)
    height 40px
    margin-bottom 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
