<template>
  <div class="AutoTypesSelect">
    <div
      class="AutoTypeItem"
      v-for="item in autoItems"
      :key="item.id"
      v-show="item.show && activeStepNo >= item.activeNo">
      <AutoType
        :typeID="typeID"
        :categoryTypeID="item.id"
        :categoryID="categoryID"
        :activeNo="item.activeNo"
        @auto-type-update="onAutoTypeUpdate"
        @auto-type-complete="onAutoTypeComplete" />
    </div>
  </div>
</template>

<script>
import AutoType from '@/components/ScreeningTypeSections/AutoType.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'

export default {
  name: 'AutoTypesSelect',
  props: {
    categoryType: {
      type: Object,
      required: true,
    },
    categoryID: {
      type: String,
      required: true,
    },
    typeID: {
      type: String,
      required: true,
    },
    mainTypeWDGID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeStepNo: 0,
      autoItems: [],
    }
  },
  computed: {
    ...mapGetters([
      'categoryTypes',
    ]),
    showPaster() {
      if ([
        'CT-87',
        'CT-88',
        'CT-93',
        'CT-94',
        'CT-95',
        'CT-96',
        'CT-97',
        'CT-98',
        'CT-99',
      ].includes(this.categoryType.id)) {
        return false
      }

      if (['C-2', 'C-3', 'C-5', 'C-7', 'C-8', 'C-10', 'C-11', 'C-17'].includes(this.categoryID)) {
        return true
      }

      return false
    },
    showWallPaper() {
      if (['CT-88'].includes(this.categoryType.id)) {
        return false
      }

      if (['C-7', 'C-8', 'C-10', 'C-11'].includes(this.categoryID)) {
        return true
      }

      return false
    },
    overallShowStatus() {
      return this.showPaster || this.showWallPaper
    },
  },
  methods: {
    setOverallShowStatusOnLoad() {
      const status = this.showPaster || this.showWallPaper
      this.$emit('show-auto-types-status', status)
    },
    setAutoTypesOnLoad() {
      this.autoItems = []

      let counter = 0
      let isFirstStepFound = false
      this.autoItems.push({
        id: 'CT-87',
        activeNo: counter,
        show: this.showPaster,
        answer: '',
        type: {},
      })
      if (!isFirstStepFound && this.showPaster) {
        this.activeStepNo = counter
        isFirstStepFound = true
      }
      counter += 1

      this.autoItems.push({
        id: 'CT-88',
        activeNo: counter,
        show: this.showWallPaper,
        answer: '',
        type: {},
      })
      if (!isFirstStepFound && this.showWallPaper) {
        this.activeStepNo = counter
        isFirstStepFound = true
      }
      counter += 1
    },
    onAutoTypeUpdate({ categoryTypeID }) {
      const autoItem = this.autoItems.find((x) => x.id === categoryTypeID)

      this.activeStepNo = autoItem.activeNo
      this.$emit('auto-type-update')
    },
    onAutoTypeComplete({ categoryTypeID, type, answer }) {
      const autoItem = this.autoItems.find((x) => x.id === categoryTypeID)

      // Set item
      autoItem.answer = answer
      autoItem.type = type

      // Next
      this.activeStepNo = autoItem.activeNo
      this.onNextStep()
    },
    onNextStep() {
      let stopped = false
      this.autoItems.forEach((autoItem) => {
        if (stopped) {
          return
        }

        if (autoItem.activeNo <= this.activeStepNo) {
          return
        }

        if (autoItem.show) {
          // Not done
          if (!autoItem.answer) {
            this.activeStepNo = autoItem.activeNo
            stopped = true
            return
          }
        }

        this.activeStepNo += 1
      })

      if (!stopped) {
        this.onComplete()
      }
    },
    onComplete() {
      this.$emit(
        'auto-types-finished',
        this.autoItems,
      )
    },
    onAutoTypeReset() {
      this.autoItems = []
      this.activeStepNo = 0
      requestAnimationFrame(() => {
        this.setOverallShowStatusOnLoad()
        this.setAutoTypesOnLoad()
      })
    },
  },
  components: {
    AutoType,
  },
  created() {
    this.setOverallShowStatusOnLoad()
    this.setAutoTypesOnLoad()

    EventBus.$on('auto-type-reset', this.onAutoTypeReset)
  },
  destroyed() {
    EventBus.$off('auto-type-reset', this.onAutoTypeReset)
  },
}
</script>

<style lang="stylus" scoped>
  .AutoTypesSelect
    margin 0 -20px 0px
    background-color $color_grey_lightest

  .AutoTypeItem
    padding 15px 20px 10px
    margin-bottom 10px
    border-top 1px solid $color_grey_lighter
    &:last-child
      border-bottom 1px solid $color_grey_lighter
</style>
