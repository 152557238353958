<template>
  <div class="PlacesSelect">
    <SectionHeadline :text="mixWB('ROOMS')" />

    <div class="PlacesList">
      <div
        class="PlaceItem"
        v-for="item in sortedSelectedPlaces"
        :key="item.id">
        <span>{{ item.name }}</span>
      </div>
      <div
        class="PlaceItem"
        v-for="place in selectedPlaces.otherPlaces"
        :key="place">
        <span>{{ place }}</span>
      </div>

      <div
        class="ManagePlaces"
        @click="onPlaceSelectClick">
        <span>{{ mixWB('ADD_OR_REMOVE') }}</span>
      </div>
    </div>

    <!-- Type places dialog -->
    <Dialog
      :isShowing="showPlaceSelectDialog"
      :useComponent="PlaceSelectDialog"
      :componentProps="{
        startingPlaces: selectedPlaces
      }"
      @place-select="onPlaceSelect"
      @close="showPlaceSelectDialog = false" />
  </div>
</template>

<script>
import Dialog from '@/components/Modals/Dialog.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import PlaceSelectDialog from '@/components/ScreeningTypeSections/PlaceSelectDialog.vue'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import { sortBy } from 'lodash-es'

export default {
  name: 'PlacesSelect',
  props: {
    typeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PlaceSelectDialog,
      showPlaceSelectDialog: false,
      selectedPlaces: {
        placeIDs: [],
        otherPlaces: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'places',
      'screeningUnits',
      'combinedListOfUnits',
    ]),
    sortedSelectedPlaces() {
      const array = this.selectedPlaces.placeIDs.reduce((prev, placeID) => {
        prev.push({
          id: placeID,
          name: this.mixWB(this.places[placeID].wordbook),
        })
        return prev
      }, [])

      return sortBy(array, ['name'])
    },
  },
  methods: {
    onPlaceSelectClick() {
      this.showPlaceSelectDialog = true
    },
    onPlaceSelect(selectedPlaces) {
      this.showPlaceSelectDialog = false
      this.selectedPlaces = selectedPlaces
      this.$emit('place-update', this.selectedPlaces)
    },
    onTypeLoad({ places }) {
      this.selectedPlaces = places
    },
  },
  components: {
    SectionHeadline,
    Dialog,
  },
  created() {
    EventBus.$on('load-type-edit', this.onTypeLoad)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .PlacesSelect
    padding-top 10px
    padding-bottom 10px

  .PlacesList
    display flex
    flex-wrap wrap
    margin 0px -5px

  .PlaceItem
    flex-center-children()
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 2.5px 5px
    border 1px solid lighten($color_grey_lighter, 25%)
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem

  .PlaceItem
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)

  .ManagePlaces
    flex-center-children()
    min-width 70px
    height 40px
    padding 5px 10px
    margin 0px 2.5px 5px
    background-color $color_primary
    cursor pointer
    box-shadow $box_shadow_1
    span
      text-transform uppercase
      color #fff
</style>
