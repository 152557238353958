<template>
  <div
    class="TypeSelect"
    :class="rootClasses">
    <SectionHeadline :text="getTitle()" />

    <div class="TypeList">
      <!-- Normal options -->
      <div
        v-for="type in typelist"
        :key="type.id"
        class="Type"
        :class="{ 'IsSelected': type.isSelected }"
        @click="onTypeClick(type)">
        <span class="OptionText">{{ type.title }}</span>
      </div>

      <!-- Other option -->
      <div
        v-if="showOtherOption"
        :class="{ 'IsSelected': isOtherType }"
        class="Type"
        key="other"
        @click="onOtherClick()">
        <span class="OptionText">{{ mixWB('OTHER') }}</span>
      </div>
    </div>

    <!-- Group type options -->
    <div
      v-if="selectedGroup"
      class="GroupTypesWrap">
      <SectionHeadline :text="mixWB('SELECT_TYPE_OF_X', [selectedGroup.wordbook])" />
      <div class="TypeList">
        <div
          class="Type"
          :class="{ 'IsSelected': type.isSelected }"
          v-for="type in selectedGroup.types"
          :key="type.id"
          @click="onTypeClick(type)">
          <span>{{ type.title }}</span>
        </div>
      </div>
    </div>

    <!-- Input field -->
    <div
      v-if="showTypeInput"
      class="OtherInputWrap">
      <InputField
        name="other-type"
        :value="otherType"
        :placeholder="mixWB('WHAT_IS_IT')"
        :focusTrigger="focusTrigger"
        @on-update="onInputUpdate"
        @enter="onEnter" />
    </div>

    <!-- Button -->
    <div
      v-if="showButton"
      class="ButtonWrap">
      <Button
        :text="mixWB('NEXT')"
        :isDisabled="isDisabled"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import { SectionHeadline, InputField } from '@/components/FormElements'
import { Button } from '@/components/Buttons'
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'

export default {
  name: 'TypeSelect',
  props: {
    preTitle: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: 'WHAT_TYPE_IS_THIS',
    },
    types: {
      type: Array,
      required: true,
    },
    selectedID: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    isOtherType: {
      type: Boolean,
      required: false,
      default: false,
    },
    otherType: {
      type: String,
      required: false,
      default: '',
    },
    showOtherOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedGroupID: null,
      focusTrigger: 0,
    }
  },
  computed: {
    ...mapGetters([
      'categoryTypeGroups',
      'currentRoute',
    ]),
    rootClasses() {
      return {
        HasBiggerPadding: this.showOtherOption,
      }
    },
    typelist() {
      const {
        types,
        groups,
      } = this.types.reduce((prev, type) => {
        const item = {
          ...type,
          isSelected: false,
          isGroup: false,
          groupID: type.ctgID,
          title: this.mixWB(type.wordbook),
        }

        // Set selected state
        if (type.hasOwnProperty('id')) {
          item.isSelected = type.id === this.selectedID
        }
        else if (type.hasOwnProperty('materialID')) {
          item.isSelected = type.materialID === this.selectedID
        }

        // Set group
        if (type.ctgID) {
          const categoryTypeGroup = this.categoryTypeGroups[type.ctgID]
          let groupToUse = prev.groups.find((x) => x.groupID === type.ctgID)
          if (!groupToUse) {
            groupToUse = {
              groupID: type.ctgID,
              isSelected: false,
              isGroup: true,
              title: this.mixWB(categoryTypeGroup.wordbook),
              wordbook: categoryTypeGroup.wordbook,
              types: [],
            }
            prev.groups.push(groupToUse)
          }

          if (item.isSelected) {
            groupToUse.isSelected = true
            this.selectedGroupID = type.ctgID
          }

          groupToUse.isSelected = groupToUse.groupID === this.selectedGroupID
          groupToUse.types.push(item)
          return prev
        }

        prev.types.push(item)
        return prev
      }, { types: [], groups: [] })

      // Update groups
      groups.forEach((group) => {
        if (group.types.length === 1) {
          types.push(group.types[0])
          return
        }
        group.types = sortBy(group.types, ((type) => type.wordbook.includes('DONT_KNOW') || type.title))
        types.push(group)
      })

      return sortBy(types, (type) => type.wordbook === 'NO' || type.wordbook === 'NONE' || type.title)
    },
    selectedGroup() {
      if (!this.selectedGroupID) {
        return null
      }
      return this.typelist.find((x) => x.groupID === this.selectedGroupID)
    },
    showTypeInput() {
      if (!this.isOtherType) {
        return false
      }

      return true
    },
    showButton() {
      if (!this.isActive) {
        return false
      }

      if (!this.isOtherType) {
        return false
      }

      return true
    },
    isDisabled() {
      return !this.otherType.length
    },
  },
  methods: {
    getTitle() {
      if (this.preTitle) {
        return `${ this.preTitle }: ${ this.mixWB(this.title) }`
      }

      return this.mixWB(this.title)
    },
    onTypeClick(type) {
      if (this.currentRoute.name !== 'ScreeningsUpdateCategoryAddType') {
        return
      }

      if (type.isGroup) {
        if (this.selectedGroupID === type.groupID) {
          return
        }
        this.selectedGroupID = type.groupID
        this.$emit('type-group-click')
        return
      }

      this.selectedGroupID = type.groupID
      this.$emit('type-click', type)
    },
    onOtherClick() {
      this.$emit('other-type-click')

      requestAnimationFrame(() => {
        this.focusTrigger += 1
      })
    },
    onInputUpdate({ value }) {
      this.$emit('other-type-input-updated', value)
    },
    onEnter() {
      this.onButtonClick()
    },
    onButtonClick() {
      if (this.isDisabled) {
        return
      }

      this.$emit('other-type-selected', this.otherType)
    },
  },
  components: {
    SectionHeadline,
    InputField,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .TypeSelect
    padding-bottom 10px
    &.HasBiggerPadding
      padding-top 10px
      padding-bottom 15px

  .TypeList
    display flex
    flex-wrap wrap
    margin 0px -5px

  .Type
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff

  .GroupTypesWrap
    padding-top 10px
</style>
