// Questions only

<template>
  <div class="SubQuestionType2">
    <!-- Questions -->
    <QuestionsSelect
      :isActive="true"
      :questions="question.questions"
      :subQuestionId="question.id"
      :resetQuestionsCounter="resetQuestionsCounter"
      @questions-updated="onQuestionsUpdated"
      @questions-all-answered="onQuestionsAllAnswered" />
  </div>
</template>

<script>
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import EventBus from '@/EventBus'

export default {
  name: 'SubQuestionType2',
  props: {
    question: {
      type: Object,
      required: true,
    },
    resetSubQuestionsCounter: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      questionData: {
        answers: {},
      },
      // Resets
      resetQuestionsCounter: 0,
    }
  },
  watch: {
    resetSubQuestionsCounter() {
      this.resetQuestions()
    },
  },
  methods: {
    onQuestionTypeLoad(answers) {
      Object.keys(answers).forEach((key) => {
        if (this.question.id !== key) {
          return
        }

        this.$set(this.questionData, 'answers', answers[key].answers)
      })

      EventBus.$emit('load-sub-question', { id: this.question.id, ...this.questionData })
    },
    onQuestionsUpdated() {
      this.$emit('question-updated')
    },
    onQuestionsAllAnswered(answers) {
      this.$set(this.questionData, 'answers', answers)
      this.onNext()
    },
    onNext() {
      this.$emit('question-updated')

      this.$emit('question-finished', {
        questionID: this.question.id,
        questionData: this.questionData,
      })
    },
    resetQuestions() {
      this.questionData.answers = {}
      this.resetQuestionsCounter += 1
    },
  },
  components: {
    QuestionsSelect,
  },
  created() {
    EventBus.$on('sub-question-load', this.onQuestionTypeLoad)
  },
  destroyed() {
    EventBus.$off('sub-question-load', this.onQuestionTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .SubQuestionType2
    display block
</style>
