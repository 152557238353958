<template>
  <div class="AmountSelect">
    <SectionHeadline :text="getTitle()" />

    <div
      v-if="unitsHelpingText.length"
      class="BuildingInfoWrap">
      <span
        v-for="item in unitsHelpingText"
        :key="item.id">{{ item.text }}</span>
    </div>

    <div class="OptionList">
      <div class="OptionsWrap">
        <div
          :class="{ 'IsSelected': selectedType === 'tons' }"
          class="Option"
          @click="onOptionClick('tons')">
          <span>{{ mixWB('TONS') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': selectedType === 'm2' }"
          class="Option"
          @click="onOptionClick('m2')">
          <span>{{ mixWB('M2') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': selectedType === 'meters' }"
          class="Option"
          @click="onOptionClick('meters')">
          <span>{{ mixWB('METERS') }}</span>
        </div>
        <div
          :class="{ 'IsSelected': selectedType === 'skip' }"
          class="Option"
          @click="onOptionClick('skip')">
          <span>{{ mixWB('SKIP') }}</span>
        </div>
      </div>
      <div v-if="showAmountInput">
        <InputField
          name="amount"
          type="number"
          :value="number"
          :placeholder="amountInputPlaceholderText"
          :focusTrigger="focusTrigger"
          :disableAutoSuggest="true"
          @on-update="onInputUpdate"
          @enter="onEnter" />
      </div>
    </div>

    <!-- Button -->
    <div
      v-if="showButton"
      class="ButtonWrap">
      <Button
        :text="buttonText"
        :isDisabled="isDisabled"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import { SectionHeadline, InputField } from '@/components/FormElements'
import { Button } from '@/components/Buttons'
import EventBus from '@/EventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'AmountSelect',
  props: {
    preTitle: {
      type: String,
      required: false,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    resetAmountCounter: {
      type: Number,
      required: true,
    },
    selectedUnitIDs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedType: '',
      number: '',
      focusTrigger: 0,
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
    ]),
    unitsHelpingText() {
      if (!this.selectedUnits) {
        return []
      }

      return this.selectedUnitIDs.reduce((prev, unitID) => {
        const building = this.selectedUnits.find(
          (x) => x.id === unitID && x.type === 'building',
        )

        if (!building || !building.bbrID) {
          return prev
        }

        const data = {
          id: unitID,
          text: `${
            this.mixWB('BUILDING')
          } ${
            building.bbrData.Bygningsnr
          }: ${
            this.mixWB('AREA')
          }: ${
            building.bbrData.BYG_BEBYG_ARL
          } ${
            this.mixWB('M2')
          }`,
        }

        prev.push(data)

        return prev
      }, [])
    },
    showButton() {
      if (!this.isActive) {
        return false
      }

      if (!this.selectedType || this.selectedType === 'skip') {
        return false
      }

      return true
    },
    isDisabled() {
      return !this.number.length
    },
    showAmountInput() {
      return !!(this.selectedType && this.selectedType !== 'skip')
    },
    amountInputPlaceholderText() {
      let typeText = ''

      if (this.selectedType === 'tons') {
        typeText = this.mixWB('TONS')
      }
      if (this.selectedType === 'm2') {
        typeText = this.mixWB('M2')
      }

      return `${ this.mixWB('HOW_MANY') } ${ typeText }?`
    },
  },
  watch: {
    resetAmountCounter() {
      this.onResetAmount()
    },
  },
  methods: {
    getTitle() {
      if (this.preTitle) {
        return `${ this.preTitle }: ${ this.mixWB('ESTIMATED_AMOUNT') }`
      }

      return this.mixWB('ESTIMATED_AMOUNT')
    },
    onInputUpdate({ value }) {
      this.number = value.replace(',', '.')

      this.$emit('amount-input-updated', value)
    },
    onEnter() {
      this.onButtonClick()
    },
    onTypeLoad({ amount }) {
      if (amount.isSkipped) {
        this.selectedType = 'skip'
        return
      }

      this.selectedType = amount.type
      this.number = amount.number
    },
    onResetAmount() {
      this.selectedType = ''
      this.number = ''
    },
    onOptionClick(option) {
      if (option === this.selectedType) {
        return
      }

      this.selectedType = option
      this.$emit('type-selected', option)

      if (option === 'skip') {
        return
      }

      requestAnimationFrame(() => {
        this.focusTrigger += 1
      })
    },
    onButtonClick() {
      if (this.isDisabled) {
        return
      }

      this.$emit('amount-selected', { type: this.selectedType, number: this.number })
    },
  },
  components: {
    SectionHeadline,
    InputField,
    Button,
  },
  created() {
    EventBus.$on('load-type-edit', this.onTypeLoad)
  },
  destroyed() {
    EventBus.$off('load-type-edit', this.onTypeLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .AmountSelect
    padding-top 15px
    padding-bottom 10px

  .BuildingInfoWrap
    padding-bottom 10px
    span
      display block
      font-size 0.75rem
      color $color_grey
      margin-bottom 2.5px

  .OptionsWrap
    display flex
    justify-content space-between

  .Option
    display flex
    align-items center
    justify-content center
    width calc(25% - 7px)
    height 40px
    margin-bottom 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
