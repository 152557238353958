<template>
  <div class="AutoType">
    <!-- Title -->
    <span class="Title">{{ mixWB(this.categoryType.wordbook) }}</span>

    <!-- Question -->
    <div class="Section">
      <SectionHeadline
        :text="getQuestionTitle()" />
      <div class="OptionsWrap">
        <div
          class="Option"
          :class="{ IsSelected: selectedAnswer === 'no' }"
          @click="onOptionNoClick">
          <span>{{ mixWB('NO') }}</span>
        </div>
        <div
          class="Option"
          :class="{ IsSelected: selectedAnswer === 'yes' }"
          @click="onOptionYesClick">
          <span>{{ mixWB('YES') }}</span>
        </div>
      </div>
    </div>

    <!-- Images -->
    <div
      v-show="activeStepNo >= 1"
      class="Section">
      <ImageSelect
        :groupName="groupName"
        :categoryID="categoryType.id"
        @image-list-update="onImageListUpdate" />
    </div>

    <!-- Coating -->
    <div
      v-show="activeStepNo >= 2"
      class="Section">
      <CoatingSelect
        :resetCoatingCounter="resetCoatingCounter"
        :groupName="groupName"
        :materialName="mixWB(categoryType.wordbook)"
        @coating-updated="onCoatingUpdated"
        @coating-finished="onCoatingFinished" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import ImageSelect from '@/components/ScreeningTypeSections/ImageSelect.vue'
import CoatingSelect from '@/components/ScreeningTypeSections/CoatingSelect.vue'
import Type from '@/globals/javascript/models/Type'

export default {
  name: 'AutoType',
  props: {
    typeID: {
      type: String,
      required: true,
    },
    categoryTypeID: {
      type: String,
      required: true,
    },
    categoryID: {
      type: String,
      required: true,
    },
    activeNo: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeStepNo: 0,
      selectedAnswer: '',
      resetCoatingCounter: 0,
      type: {},
      roadMap: [
        'question', // 0
        'images', // 1
        'coating', // 2
      ],
    }
  },
  computed: {
    ...mapGetters([
      'categoryTypes',
      'currentRoute',
    ]),
    activeStep() {
      return this.roadMap[this.activeStepNo]
    },
    categoryType() {
      return this.categoryTypes.find((x) => x.id === this.categoryTypeID)
    },
    groupName() {
      return `auto-type-${ this.categoryType.id }`
    },
  },
  methods: {
    getQuestionTitle() {
      return this.mixWB('IS_THERE_X', [this.mixWB(this.categoryType.wordbook).toLowerCase()])
    },
    onOptionYesClick() {
      if (this.selectedAnswer === 'yes') {
        return
      }

      this.$emit('auto-type-update', { categoryTypeID: this.categoryTypeID })

      this.selectedAnswer = 'yes'

      this.type = new Type({
        ...this.type,
        autoTypeBaseID: this.typeID,
        type: {
          typeID: this.categoryTypeID,
        },
      })

      this.onNextStep()
    },
    onOptionNoClick() {
      if (this.selectedAnswer === 'no') {
        return
      }

      this.$emit('auto-type-update', { categoryTypeID: this.categoryTypeID })

      this.activeStepNo = 0

      this.selectedAnswer = 'no'

      this.onComplete()
    },
    onImageListUpdate(imageList) {
      this.$emit('auto-type-update', { categoryTypeID: this.categoryTypeID })
      this.type.images = imageList

      if (!imageList.length) {
        this.activeStepNo = 1
        return
      }

      this.onNextStep()
    },
    onCoatingUpdated() {
      this.$emit('auto-type-update', { categoryTypeID: this.categoryTypeID })
    },
    onCoatingFinished(coatingData) {
      this.$set(this.type, 'coating', coatingData)
      this.onNextStep()
    },
    onNextStep() {
      if (this.activeStep === 'question') {
        this.activeStepNo += 1
        if (this.type.images.length) {
          this.onNextStep()
        }
        return
      }

      if (this.activeStep === 'images') {
        // Check if coating is done
        this.activeStepNo += 1
        if (this.type.coating.mainOptionID) {
          this.onNextStep()
        }
        return
      }

      if (this.activeStep === 'coating') {
        // do nothing
      }

      this.onComplete()
    },
    onComplete() {
      this.$emit('auto-type-complete', {
        answer: this.selectedAnswer,
        categoryTypeID: this.categoryTypeID,
        type: this.type,
      })
    },
  },
  components: {
    SectionHeadline,
    ImageSelect,
    CoatingSelect,
  },
}
</script>

<style lang="stylus" scoped>
  .AutoType
    span
      display block

  .Title
    text-transform uppercase
    margin-bottom 10px
    font-size 1.125rem

  .Section
    margin-bottom 10px

  .OptionsWrap
    display flex
    align-items center

  .Option
    display flex
    align-items center
    justify-content center
    width calc(33.33% - 7px)
    margin-right 10px
    margin-bottom 10px
    height 40px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
