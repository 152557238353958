<template>
  <div class="CoatingSelect">
    <!-- Main option select -->
    <SectionHeadline
      :text="`${ mixWB('IS_THERE_X_DIRECTLY_ON_Y', [mixWB('COATING'), materialName ]) }`" />
    <div class="OptionList">
      <div
        v-show="activeStepNo >= 0"
        v-for="option in questionSchema.options"
        :key="option.id"
        class="Option"
        :class="{ 'IsSelected': option.id === savedData.mainOptionID }"
        @click="onMainOptionClick(option.id)">
        <span>{{ mixWB(option.wordbook) }}</span>
      </div>
    </div>

    <!-- Color select -->
    <ColorSelect
      v-show="activeStepNo >= 1 && showColorSelection"
      :selectedColorIDs="savedData.colorIDs"
      @color-update="onColorUpdate"  />

    <!-- Questions -->
    <QuestionsSelect
      v-show="activeStepNo >= 2 && mainOptionQuestions.length"
      :isActive="activeStepNo === 2"
      :questions="mainOptionQuestions"
      :resetQuestionsCounter="resetQuestionsCounter"
      subQuestionId="coating"
      @questions-updated="onQuestionsUpdated"
      @questions-all-answered="onQuestionsAllAnswered" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import ColorSelect from '@/components/ScreeningTypeSections/ColorSelect.vue'
import QuestionsSelect from '@/components/ScreeningTypeSections/QuestionsSelect.vue'
import { mapGetters } from 'vuex'
import { union } from 'lodash-es'
import EventBus from '@/EventBus'
import { findTags } from '@/views/helpers'

export default {
  name: 'CoatingSelect',
  props: {
    resetCoatingCounter: {
      type: Number,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    materialName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      version: '1.0', // X.Y - Bump X when doing breaking changes. Bump Y when doing non-breaking changes.
      activeStepNo: 0,
      roadMap: [
        'main-options', // 0
        'color-select', // 1
        'questions', // 2
      ],
      resetQuestionsCounter: 0,
      savedData: {
        version: null,
        mainOptionID: null,
        colorIDs: [],
        answers: {},
        coatingTypeIDs: [],
        samples: {
          sampleIDs: [],
          items: [],
        },
        tags: {},
      },
      questions: [
        {
          id: 'Q-1',
          alwaysShow: true,
          areaBased: ['outside'],
          position: 0,
          sampleIDs: ['ST-4'],
          tagIDs: ['TAG-1978'],
          wordbook: 'FROM_BEFORE_1978',
        },
        {
          id: 'Q-2',
          alwaysShow: true,
          colorBased: ['CO-2'],
          position: 1,
          sampleIDs: ['ST-3'],
          tagIDs: ['TAG-9'],
          wordbook: 'RISK_OF_TAR',
        },
      ],
      questionSchema: {
        options: [
          {
            dkName: 'Ingen',
            id: 'O-1',
            wordbook: 'NONE',
          },
          {
            dkName: 'Maling',
            askForColor: true,
            coatingTypeIDs: ['COAT-2'],
            id: 'O-2',
            sampleIDs: ['ST-2'],
            wordbook: 'PAINT',
          },
          {
            dkName: 'Kalk',
            askForColor: true,
            coatingTypeIDs: ['COAT-3'],
            id: 'O-3',
            sampleIDs: ['ST-2'],
            wordbook: 'LIME',
          },
          {
            dkName: 'Maling og kalk',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-3'],
            id: 'O-4',
            sampleIDs: ['ST-2'],
            wordbook: 'PAINT_AND_LIME_2',
          },
          {
            dkName: 'Maling eller kalk',
            askForColor: true,
            coatingTypeIDs: ['COAT-4'],
            id: 'O-5',
            sampleIDs: ['ST-2'],
            wordbook: 'PAINT_OR_LIME',
          },
          {
            dkName: 'Lak',
            askForColor: false,
            coatingTypeIDs: ['COAT-5'],
            id: 'O-6',
            sampleIDs: ['ST-2'],
            wordbook: 'LACQUER',
          },
          {
            dkName: 'Fernis',
            askForColor: false,
            coatingTypeIDs: ['COAT-6'],
            id: 'O-7',
            sampleIDs: ['ST-2'],
            wordbook: 'VARNISH',
          },
          {
            dkName: 'Lak eller fernis',
            askForColor: false,
            coatingTypeIDs: ['COAT-7'],
            id: 'O-8',
            sampleIDs: ['ST-2'],
            wordbook: 'LACQUER_OR_VARNISH',
          },
          {
            dkName: 'Maling og lak',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-5'],
            id: 'O-9',
            sampleIDs: ['ST-2'],
            wordbook: 'PAINT_AND_LACQUER',
          },
          {
            dkName: 'Maling og fernis',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-6'],
            id: 'O-10',
            sampleIDs: ['ST-2'],
            wordbook: 'PAINT_AND_VARNISH',
          },
          {
            dkName: 'Maling og lak eller fernis',
            askForColor: true,
            coatingTypeIDs: ['COAT-2', 'COAT-7'],
            id: 'O-11',
            sampleIDs: ['ST-2'],
            wordbook: 'PAINT_AND_LACQUER_OR_VARNISH',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentCategory',
    ]),
    selectedMainOption() {
      return this.questionSchema.options.find((x) => x.id === this.savedData.mainOptionID)
    },
    showColorSelection() {
      if (!this.selectedMainOption) {
        return false
      }

      if (this.selectedMainOption.askForColor) {
        return true
      }

      return false
    },
    mainOptionQuestions() {
      if (!this.selectedMainOption) {
        return []
      }

      if (this.selectedMainOption.id === 'O-1') {
        return []
      }

      return this.questions.reduce((prev, question) => {
        let addQuestion = true

        // Check to add color question
        if (question.colorBased) {
          let allGood = false
          question.colorBased.forEach((colorID) => {
            if (allGood) {
              return
            }
            allGood = !!this.savedData.colorIDs.includes(colorID)
          })
          if (!allGood) {
            addQuestion = false
          }
        }

        // Check to add question based on area
        if (addQuestion && question.areaBased) {
          addQuestion = !!question.areaBased.includes(this.currentCategory.place)
        }

        if (addQuestion) {
          prev.push(question)
        }

        return prev
      }, [])
    },
  },
  watch: {
    resetCoatingCounter() {
      this.onResetCoating()
    },
  },
  methods: {
    onMainOptionClick(optionID) {
      if (this.savedData.mainOptionID === optionID) {
        return
      }

      this.activeStepNo = 0
      this.savedData.mainOptionID = optionID

      // Reset
      this.savedData.colorIDs = []
      this.savedData.answers = {}
      this.savedData.coatingTypeIDs = []
      this.savedData.samples.sampleIDs = []
      this.savedData.samples.items = []
      this.resetQuestionsCounter += 1

      this.onNextStep()
    },
    onColorUpdate(colorIDs) {
      this.savedData.colorIDs = colorIDs

      this.activeStepNo = 1

      // Reset
      this.savedData.coatingTypeIDs = []
      this.savedData.samples.sampleIDs = []
      this.savedData.samples.items = []
      this.resetQuestionsCounter += 1

      if (!colorIDs.length) {
        this.$emit('coating-updated')
        return
      }

      this.onNextStep()
    },
    onQuestionsUpdated() {
      this.$emit('coating-updated')
    },
    onQuestionsAllAnswered(answers) {
      this.$set(this.savedData, 'answers', answers)
      this.onNextStep()
    },
    onNextStep() {
      this.$emit('coating-updated')

      if (this.activeStepNo === 0) {
        // Color select
        if (this.showColorSelection) {
          this.activeStepNo += 1
          return
        }

        // Questions
        if (this.mainOptionQuestions.length) {
          this.activeStepNo += 2
          return
        }
      }

      if (this.activeStepNo === 1) {
        // Questions
        if (this.mainOptionQuestions.length) {
          this.activeStepNo += 1
          return
        }
      }

      if (this.activeStepNo === 2) {
        // Do nothing
      }

      this.saveCoatingAndSamples()

      // Set version if missing
      if (!this.savedData.version) {
        this.savedData.version = this.version
      }

      this.$emit('coating-finished', this.savedData)
    },
    saveCoatingAndSamples() {
      this.savedData.coatingTypeIDs = []
      this.savedData.samples.sampleIDs = []
      this.savedData.samples.items = []

      const tags = findTags({
        mainQuestions: this.questions,
        mainQuestionAnswers: this.savedData.answers,
      })

      this.savedData.tags = tags

      if (this.selectedMainOption.id === 'O-1') {
        return
      }

      // Set coating types from selected option
      if (this.selectedMainOption.coatingTypeIDs.length) {
        this.savedData.coatingTypeIDs = this.selectedMainOption.coatingTypeIDs
      }

      // Set sample from selected option
      if (this.selectedMainOption.sampleIDs.length) {
        this.savedData.samples.sampleIDs = this.savedData.samples.sampleIDs.concat(
          this.selectedMainOption.sampleIDs,
        )
        this.savedData.samples.items.push({
          isMain: true,
          optionID: this.selectedMainOption.id,
          sampleIDs: this.selectedMainOption.sampleIDs,
        })
      }

      Object.keys(this.savedData.answers).forEach((questionID) => {
        if (this.savedData.answers[questionID] === 0) {
          return
        }

        const question = this.mainOptionQuestions.find((x) => x.id === questionID)

        // Remove answer if question is no longer available
        if (!question) {
          delete this.savedData.answers[questionID]
          return
        }

        if (question.sampleIDs && question.sampleIDs.length) {
          this.savedData.samples.items.push({
            isQuestion: true,
            questionID,
            answer: this.savedData.answers[questionID],
            sampleIDs: question.sampleIDs,
          })

          this.savedData.samples.sampleIDs = union(
            this.savedData.samples.sampleIDs,
            question.sampleIDs,
          )
        }
      })
    },
    onTypeCoatingLoad({ coating, groupName }) {
      if (this.groupName !== groupName) {
        return
      }

      this.$set(this, 'savedData', coating)
      this.activeStepNo = this.roadMap.length - 1
      EventBus.$emit('load-sub-question', { id: 'coating', ...this.savedData })
    },
    onResetCoating() {
      this.savedData = {
        version: null,
        mainOptionID: null,
        colorIDs: [],
        answers: {},
        coatingTypeIDs: [],
        samples: {
          sampleIDs: [],
          items: [],
        },
      }
    },
  },
  components: {
    SectionHeadline,
    ColorSelect,
    QuestionsSelect,
  },
  created() {
    EventBus.$on('load-type-coating', this.onTypeCoatingLoad)
  },
  destroyed() {
    EventBus.$off('load-type-coating', this.onTypeCoatingLoad)
  },
}
</script>

<style lang="stylus" scoped>
  .CoatingSelect
    margin 0 -20px 0px
    padding 20px 20px 5px
    background-color $color_grey_lightest
    border-top 1px solid $color_grey_lighter
    span
      display block

  .OptionList
    display flex
    flex-wrap wrap
    margin 0px -5px 15px

  .Option
    display flex
    align-items center
    justify-content center
    min-width 70px
    min-height 40px
    padding 5px 10px
    margin 0px 5px 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    cursor pointer
    span
      display block
      text-align center
      text-transform uppercase
      font-size 0.875rem
    &.IsSelected
      background-color $color_primary
      border-color $color_primary
      box-shadow $box_shadow_1
      span
        color #fff
</style>
