<template>
  <div
    class="UnitSelectGroupUnit"
    :class="rootClasses"
    @click="onUnitClick">
    <div class="CheckboxWrap">
      <div
        :class="{
          'IsSelected': isSelected,
        }"
        class="CheckBox">
        <CheckIcon class="CheckIcon" />
      </div>
    </div>
    <div
      class="ImageWrap"
      v-if="isFirstInGroup">
      <MultiImage
        v-if="unitProfileImage"
        :image="unitProfileImage"
        preview />
    </div>
    <div class="InfoWrap">
      <div class="TitleWrap">
        <span class="Title">{{ unit.getFullTitle() }}</span>
        <span>({{ unit.getTypeText() }})</span>
      </div>
      <div
        class="ExtraInfo"
        v-if="unit.type === 'building'">
        <span v-if="unit.getBuildingTypeCodeText()">{{ unit.getBuildingTypeCodeText() }}</span>
        <span v-if="unit.getBuiltArea()">
          {{ mixWB('BUILT_AREA') }}: {{ unit.getBuiltArea() }} {{ mixWB('M2') }}
        </span>
        <span v-if="unit.getYearBuilt()">{{ mixWB('YEAR_BUILT') }}: {{ unit.getYearBuilt() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MultiImage from '@/components/Images/MultiImage.vue'
import CheckIcon from '@/assets/svg/check.svg?inline'

export default {
  name: 'UnitSelectGroupUnit',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    isFirstInGroup: {
      type: Boolean,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    topLevel: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'addressImages',
    ]),
    rootClasses() {
      return {
        IsFirstChild: !this.isFirstInGroup
          && (
            (this.topLevel === 'building' && this.unit.type === 'floor')
            || (this.topLevel === 'floor' && this.unit.type === 'apartment')
          ),
        IsSecondChild: !this.isFirstInGroup
          && (this.topLevel === 'building' && this.unit.type === 'apartment'),
      }
    },
    unitProfileImage() {
      if (!this.addressImages?.units?.[this.unit.id]?.profile) {
        return false
      }

      return this.addressImages.units[this.unit.id].profile[0]
    },
  },
  methods: {
    onUnitClick() {
      this.$emit('unit-click', this.unit.id)
    },
  },
  components: {
    MultiImage,
    CheckIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitSelectGroupUnit
    position relative
    display flex
    align-items center
    margin-bottom 10px
    &.IsFirstChild
      margin-left 42px
    &.IsSecondChild
      margin-left 82px

  .CheckboxWrap
    flex-center-children()
    .CheckBox
      box(26px)
      padding 2px
      margin-right 15px
      background-color #fff
      border 1px solid #777
      .CheckIcon
        display none
      &.IsSelected
        border 1px solid $color_primary
        .CheckIcon
          display block
          fill $color_primary

  .ImageWrap
    flex-shrink 0
    box(60px)
    border 1px solid $color_grey_lighter
    margin-right 10px
    background-color $color_grey_lightest

  .InfoWrap
    min-width 0
    span
      display block
      font-size 0.75rem
      padding-bottom 2px
      truncated()
    .TitleWrap
      height 15px
      span
        display inline-block
    .Title
      font-weight bold
      text-transform uppercase
      padding-right 5px
</style>
